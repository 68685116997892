import React, { useEffect, useState } from 'react';
import { Oval } from 'react-loader-spinner';
import Navbar from './components/Navbar/Navbar';
import AppRoutes from './routes/AppRoutes';
import ScrollButton from './components/ScrollToTop/ScrollButton';
import Footer from './components/Footer/Footer';
import './App.scss';

function App() {
  const [loading, setLoading] = useState(true);
  useEffect(() => {
    setTimeout(() => {
      setLoading(false);
    }, 2000);
  }, []);
  return (
    <div>
      <div className={loading ? 'loader loading' : 'loader'}>
        {loading ? (
          <Oval height="30vh" width="30vw" color="grey" />
        ) : (
          <div className="App">
            <ScrollButton />
            <Navbar />
            <main className="main">
              <AppRoutes />
            </main>
            <Footer />
          </div>
        )}
      </div>
    </div>
  );
}

export default App;
