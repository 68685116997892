/*eslint-disable*/
import React, {useState} from 'react';
import { Link } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';
import Button from "../Button/Button";
import Phone from "../../images/phone";
import Modal from "../Modal/Modal";
import './Product.scss'



const Product = ({ item }) => {
    const [productModal, setProductModal] = useState(false);
    const navigate = useNavigate();
    return (
        <div className="item">
            <Link className="item__link" to={`/products/${item._id}`}>
                <img alt="product" className="item__link--img" src={item.img} />
                 <p className="item__link--title">{item.title}</p>
            </Link>
            <div className="item__block">
            <Button onClick={()=>navigate(`/products/${item._id}`)} className="item--button">Детальніше</Button>
            <Phone onClick={() =>
                setProductModal(true)
            } classname="item__block--phone" color="black"/>
            </div>
            <Modal active={productModal} setActive={setProductModal}>
                <div className="nav__modal">
                    <h1 className="nav__modal--title">Зв’яжіться з нами</h1>
                    <div className="nav__modal--border" />
                    <a className="nav__modal--contact" href="mailto:inter-kanat@ukr.net">
                        Пошта: inter-kanat@ukr.net
                    </a>
                    <a className="nav__modal--contact" href="tel:+380675779282">
                        Телефон: +38 (067) 577-92-82
                    </a>

                    <a className="nav__modal--contact" href="tel:+380677449572">
                        Телефон: +38 (067) 744-95-72
                    </a>
                </div>
            </Modal>
        </div>

    );
};

export default Product;
