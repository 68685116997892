import React from 'react';
import PropTypes from 'prop-types';

function Cash({ classname, color }) {
  return (
    <svg
      width="56"
      height="56"
      viewBox="0 0 56 56"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={classname}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M38.5 52.5C42.213 52.5 45.774 51.025 48.3995 48.3995C51.025 45.774 52.5 42.213 52.5 38.5C52.5 34.787 51.025 31.226 48.3995 28.6005C45.774 25.975 42.213 24.5 38.5 24.5C34.787 24.5 31.226 25.975 28.6005 28.6005C25.975 31.226 24.5 34.787 24.5 38.5C24.5 42.213 25.975 45.774 28.6005 48.3995C31.226 51.025 34.787 52.5 38.5 52.5ZM56 38.5C56 43.1413 54.1563 47.5925 50.8744 50.8744C47.5925 54.1563 43.1413 56 38.5 56C33.8587 56 29.4075 54.1563 26.1256 50.8744C22.8437 47.5925 21 43.1413 21 38.5C21 33.8587 22.8437 29.4075 26.1256 26.1256C29.4075 22.8437 33.8587 21 38.5 21C43.1413 21 47.5925 22.8437 50.8744 26.1256C54.1563 29.4075 56 33.8587 56 38.5Z"
        fill={color}
      />
      <path
        d="M33.033 41.804C33.1975 43.89 34.846 45.514 37.8035 45.71V47.25H39.116V45.6995C42.1785 45.486 43.967 43.848 43.967 41.475C43.967 39.312 42.602 38.199 40.152 37.625L39.116 37.38V33.18C40.432 33.3305 41.265 34.048 41.4645 35.042H43.7675C43.603 33.0295 41.8775 31.458 39.116 31.2865V29.75H37.8035V31.325C35.189 31.5805 33.411 33.152 33.411 35.378C33.411 37.345 34.734 38.598 36.9355 39.109L37.8035 39.3225V43.7745C36.4595 43.5715 35.567 42.8295 35.3675 41.804H33.0295H33.033ZM37.793 37.065C36.5015 36.7675 35.8015 36.155 35.8015 35.238C35.8015 34.209 36.5575 33.439 37.8035 33.215V37.065H37.793ZM39.305 39.676C40.8765 40.04 41.5975 40.628 41.5975 41.6675C41.5975 42.854 40.698 43.666 39.116 43.8165V39.634L39.305 39.676Z"
        fill={color}
      />
      <path
        d="M3.5 0C2.57174 0 1.6815 0.368749 1.02513 1.02513C0.368749 1.6815 0 2.57174 0 3.5L0 31.5C0 32.4283 0.368749 33.3185 1.02513 33.9749C1.6815 34.6312 2.57174 35 3.5 35H17.7905C17.9935 33.796 18.298 32.627 18.6935 31.5H10.5C10.5 29.6435 9.7625 27.863 8.44975 26.5503C7.13699 25.2375 5.35652 24.5 3.5 24.5V10.5C5.35652 10.5 7.13699 9.7625 8.44975 8.44975C9.7625 7.13699 10.5 5.35652 10.5 3.5H45.5C45.5 5.35652 46.2375 7.13699 47.5503 8.44975C48.863 9.7625 50.6435 10.5 52.5 10.5V22.848C53.83 24.038 55.0095 25.396 56 26.887V3.5C56 2.57174 55.6312 1.6815 54.9749 1.02513C54.3185 0.368749 53.4283 0 52.5 0L3.5 0Z"
        fill={color}
      />
      <path
        d="M34.993 17.7904L35 17.4999C34.9993 16.2945 34.6873 15.1097 34.0942 14.0602C33.5012 13.0108 32.6472 12.1322 31.6149 11.5097C30.5827 10.8872 29.4072 10.5418 28.2022 10.507C26.9973 10.4722 25.8038 10.7491 24.7373 11.3109C23.6708 11.8728 22.7675 12.7005 22.1148 13.714C21.4622 14.7274 21.0823 15.8922 21.0119 17.0956C20.9416 18.299 21.1832 19.5001 21.7133 20.5827C22.2435 21.6653 23.0442 22.5926 24.038 23.2749C27.0612 20.3954 30.8762 18.4854 34.993 17.7904Z"
        fill={color}
      />
    </svg>
  );
}

Cash.propTypes = {
  classname: PropTypes.string,
  color: PropTypes.string,
};
Cash.defaultProps = {
  classname: '',
  color: '#1F1F1F',
};

export default Cash;
