import React from 'react';
import GalleryItem from '../../components/Gallery/Gallery';
import './Gallery.scss';

function Gallery() {
  return (
    <div className="container">
      <h1 className="title">Галерея</h1>
      <GalleryItem />
    </div>
  );
}

export default Gallery;
