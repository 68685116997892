import React from 'react';
import './TermsUse.scss';

function TermsUse() {
  return (
    <div className="container">
      <h1 className="title">Умови використання</h1>
      <div className="terms">
        <h3 className="terms--title">Використання сайту</h3>
        <p>
          На сайті www.litacom.com.ua дозволено переглядати і завантажувати
          матеріали тільки для особистого некомерційного використання, за умови
          збереження вами всієї інформації про авторське право та інших
          відомостей про право власності, що містяться у вихідних матеріалах і
          будь-яких їхніх копіях. Забороняється змінювати матеріали сайту, а
          також поширювати або демонструвати їх в будь-якому вигляді або
          використовувати їх будь-яким іншим чином для суспільних або
          комерційних цілей.
        </p>
        <h3 className="terms--title">Відмова від відповідальності </h3>
        <p>
          Матеріали та послуги цього сайту надаються «як є» без будь-яких
          гарантій. Літаком не гарантує точності і повноти матеріалів, програм і
          послуг, що надаються на сайті, а також в будь-який час без
          повідомлення може вносити зміни в матеріали та послуги, що надаються
          на цьому. Літаком ні за яких обставин не несе відповідальності за
          будь-які збитки (включаючи, але не обмежуючись збитком від втрати
          прибутку, даних або від переривання ділової активності), що виник
          внаслідок використання, неможливості використання або результатів
          використання цього сайту.
        </p>
      </div>
    </div>
  );
}

export default TermsUse;
