import React from 'react';
import './Map.scss';
import { Link } from 'react-router-dom';

function Map() {
  return (
    <div className="container">
      <ul className="map">
        <li>
          <Link className="map--link" to="/main">
            Головна
          </Link>
        </li>
        <li>
          <Link className="map--link" to="/catalog">
            Каталог
          </Link>
        </li>
        <li>
          <Link className="map--link" to="/delivery">
            Доставка
          </Link>
        </li>
        <li>
          <Link className="map--link" to="/warranty">
            Гарантія
          </Link>
        </li>
        <li>
          <Link className="map--link" to="/requisites">
            Реквізити
          </Link>
        </li>
        <li>
          <Link className="map--link" to="/about">
            Про нас
          </Link>
        </li>
        <li>
          <Link className="map--link" to="/gallery">
            Галерея
          </Link>
        </li>
        <li>
          <Link className="map--link" to="/discounts">
            Знижки
          </Link>
        </li>
        <li>
          <Link className="map--link" to="/terms-use">
            Умови використання
          </Link>
        </li>
      </ul>
    </div>
  );
}

export default Map;
