import React from 'react';
import './Requisites.scss';

const doc1 = require('../../images/doc1.jpg').default;
const doc2 = require('../../images/doc2.jpg').default;
const doc3 = require('../../images/doc3.jpg').default;

function Requisites() {
  return (
    <div className="container">
      <h1 className="title">Реквізити</h1>
      <div className="requisites">
        <h2>ТОВАРИСТВО З ОБМЕЖЕНОЮ ВІДПОВІДАЛЬНІСТЮ «ЛІТАКОМ»</h2>
        <p>
          Юридична адреса: Україна 03061 м.Київ проспект Відрадний буд.95 1-К,оф
          406
        </p>
        <p>Фактична адреса: Україна 02081 м.Київ вул.Причальна 5а</p>
        <p>ЄДРПОУ: 40250333 </p>
        <p>ІПН: 402503326583</p>
        <p>Директор: Карпенко Олександр Олександрович </p>
        <div>
          <div className="requisites__block">
            <p>Номери телефону:</p>
            <div className="requisites__block--phones">
              <a href="tel:+380675779282">+38 (067) 577-92-82</a>
              <a href="tel:+380677449572">+38 (067) 744-95-72</a>
            </div>
          </div>
        </div>
        <a href="mailto:inter-kanat@ukr.net">E-mail: inter-kanat@ukr.net</a>
        <div className="requisites__images">
          <a className="requisites__images" href={doc1}>
            <img className="requisites__images--img" alt="doc" src={doc1} />
          </a>
          <a className="requisites__images" href={doc2}>
            <img className="requisites__images--img" alt="doc" src={doc2} />
          </a>
          <a className="requisites__images" href={doc3}>
            <img className="requisites__images--img" alt="doc" src={doc3} />
          </a>
        </div>
      </div>
    </div>
  );
}

export default Requisites;
