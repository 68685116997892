import React from 'react';
import PropTypes from 'prop-types';
import './Pagination.scss';

function Pagination({ itemsPerPage, totalPages, paginate }) {
  const pageNumbers = [];
  // eslint-disable-next-line no-plusplus
  for (let i = 1; i <= Math.ceil(totalPages / itemsPerPage); i++) {
    pageNumbers.push(i);
  }

  return (
    <div>
      <ul className="pagination">
        {pageNumbers.map((number) => (
          // eslint-disable-next-line jsx-a11y/click-events-have-key-events,jsx-a11y/no-noninteractive-element-interactions
          <li className="pagination--item" key={number}>
            {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
            <a
              href="#"
              onClick={() => paginate(number)}
              className="pagination--link"
            >
              {number}
            </a>
          </li>
        ))}
      </ul>
    </div>
  );
}

Pagination.propTypes = {
  itemsPerPage: PropTypes.number,
  totalPages: PropTypes.number,
  paginate: PropTypes.func,
};
Pagination.defaultProps = {
  itemsPerPage: 0,
  totalPages: 0,
  paginate: () => {},
};

export default Pagination;
