import { useLocation } from 'react-router-dom';
import React, { useEffect, useState } from 'react';
import axios from 'axios';
import Button from '../../components/Button/Button';
import Modal from '../../components/Modal/Modal';
import './Product.scss';

function Product() {
  const location = useLocation();
  const id = location.pathname.split('/')[2];
  const [product, setProduct] = useState({});
  const [modalActive, setModalActive] = useState(false);

  useEffect(() => {
    const getProduct = async () => {
      try {
        const res = await axios.get(`
        //31.131.31.43:2145/api/products/find/${id}`);
        setProduct(res.data);
        // eslint-disable-next-line no-empty
      } catch (err) {}
    };
    getProduct();
  }, [id]);

  return (
    <div className="container">
      <p className="title title--uppercase">{product.title}</p>
      <div className="product">
        <div className="product__block">
          <img className="product__block--image" alt="img" src={product.img} />
        </div>
        <div className="product__info">
          <p>{product.material}</p>
          <p>{product.standard}</p>
          <p>{product.coef}</p>
          <p>{product.temp}</p>
          <p>{product.warranty}</p>
          <p>{product.manufacturer}</p>
          <a className="product__info--link" href={`${product.passport}`}>
            Паспорт товару
          </a>
          <div>
            <p>Дізнатися більше або замовити товар:</p>
            <Button
              onClick={() => setModalActive(true)}
              className="item--button"
            >
              Детальніше
            </Button>
          </div>
        </div>
      </div>
      <div className="product__description">
        <p className="product__description--title">Опис товару</p>
        <p>{product.desc}</p>
      </div>
      <Modal active={modalActive} setActive={setModalActive}>
        <div className="nav__modal">
          <h1 className="nav__modal--title">Зв’яжіться з нами</h1>
          <div className="nav__modal--border" />
          <a className="nav__modal--contact" href="mailto:inter-kanat@ukr.net">
            Пошта: inter-kanat@ukr.net
          </a>
          <a className="nav__modal--contact" href="tel:+380675779282">
            Телефон: +38 (067) 577-92-82
          </a>

          <a className="nav__modal--contact" href="tel:+380677449572">
            Телефон: +38 (067) 744-95-72
          </a>
        </div>
      </Modal>
    </div>
  );
}
export default Product;
