import React from 'react';
import './Warranty.scss';

function Warranty() {
  return (
    <div className="container">
      <h1 className="title">Гарантія</h1>
      <div className="warranty">
        <p className="warranty__text">
          ТОВ &rdquo;ЛІТАКОМ&rdquo; на всю реалізовану продукцію в
          обов&apos;язковому порядку надає паспорт або сертифікат із зазначенням
          гарантійного терміну. Протягом цього терміну наша компанія несе
          гарантійні зобов&apos;язання і приймає претензії щодо якості
          продукції. Гарантійний термін встановлюється згідно діючих стандартів
          та нормативних документів і складає від 1 до 6 місяців, залежно від
          типу продукції.
        </p>
      </div>
    </div>
  );
}

export default Warranty;
