import React, { useState } from 'react';
import { Link, NavLink } from 'react-router-dom';
import Logo from '../../images/logo';
import Catalogue from '../../images/catalogue';
import Contacts from '../../images/contacts';
import Car from '../../images/car';
import Bars from '../../images/bars';
import './Navbar.scss';
import Modal from '../Modal/Modal';

function Navbar() {
  const [sidebar, setSidebar] = useState(false);
  const showSidebar = () => setSidebar(!sidebar);
  const [modalActive, setModalActive] = useState(false);

  return (
    <header className="nav">
      <div className="container">
        <div className="nav--content">
          <Bars onclick={showSidebar} classname="nav--bars" />

          <Link onClick={showSidebar} to="/" className="nav--log">
            <Logo classname="nav--log__item" />
          </Link>
          <div
            className={
              sidebar ? 'nav--content__left active' : 'nav--content__left'
            }
          >
            <NavLink onClick={showSidebar} to="/catalog" className="nav--item">
              <Catalogue classname="nav--svg" />
              <p className="nav--item__text">Каталог</p>
            </NavLink>
            {/* eslint-disable-next-line jsx-a11y/click-events-have-key-events,jsx-a11y/no-static-element-interactions */}
            <div
              onClick={() => {
                setModalActive(true);
                setSidebar(false);
              }}
              className="nav--item"
            >
              <Contacts classname="nav--svg" />
              <p className="nav--item__text">Контакти</p>
            </div>
            <NavLink onClick={showSidebar} to="/delivery" className="nav--item">
              <Car classname="nav--svg" />
              <p className="nav--item__text">Доставка</p>
            </NavLink>
          </div>
        </div>
      </div>
      <Modal active={modalActive} setActive={setModalActive}>
        <div className="nav__modal">
          <h1 className="nav__modal--title">Зв’яжіться з нами</h1>
          <div className="nav__modal--border" />
          <a className="nav__modal--contact" href="mailto:inter-kanat@ukr.net">
            Пошта: inter-kanat@ukr.net
          </a>
          <a className="nav__modal--contact" href="tel:+380675779282">
            Телефон: +38 (067) 577-92-82
          </a>

          <a className="nav__modal--contact" href="tel:+380677449572">
            Телефон: +38 (067) 744-95-72
          </a>
        </div>
      </Modal>
    </header>
  );
}

export default Navbar;
