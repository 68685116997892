import React from 'react';
import PropTypes from 'prop-types';

function ArrowDown({ classname, color }) {
  return (
    <svg
      className={classname}
      width="65"
      height="63"
      viewBox="0 0 65 63"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M4.0625 31.5C4.0625 38.81 7.05858 45.8207 12.3917 50.9896C17.7247 56.1586 24.9579 59.0625 32.5 59.0625C40.0421 59.0625 47.2753 56.1586 52.6083 50.9896C57.9414 45.8207 60.9375 38.81 60.9375 31.5C60.9375 24.19 57.9414 17.1793 52.6083 12.0104C47.2753 6.8414 40.0421 3.9375 32.5 3.9375C24.9579 3.9375 17.7247 6.8414 12.3917 12.0104C7.05858 17.1793 4.0625 24.19 4.0625 31.5ZM65 31.5C65 39.8543 61.5759 47.8665 55.481 53.7739C49.386 59.6813 41.1195 63 32.5 63C23.8805 63 15.614 59.6813 9.51903 53.7739C3.4241 47.8665 0 39.8543 0 31.5C0 23.1457 3.4241 15.1335 9.51903 9.22614C15.614 3.31874 23.8805 0 32.5 0C41.1195 0 49.386 3.31874 55.481 9.22614C61.5759 15.1335 65 23.1457 65 31.5ZM34.5312 17.7188C34.5312 17.1966 34.3172 16.6958 33.9363 16.3266C33.5554 15.9574 33.0387 15.75 32.5 15.75C31.9613 15.75 31.4446 15.9574 31.0637 16.3266C30.6828 16.6958 30.4688 17.1966 30.4688 17.7188V40.5287L21.7506 32.0749C21.3692 31.7052 20.8519 31.4975 20.3125 31.4975C19.7731 31.4975 19.2558 31.7052 18.8744 32.0749C18.493 32.4446 18.2787 32.9459 18.2787 33.4688C18.2787 33.9916 18.493 34.4929 18.8744 34.8626L31.0619 46.6751C31.2506 46.8585 31.4747 47.0039 31.7215 47.1032C31.9683 47.2024 32.2328 47.2535 32.5 47.2535C32.7672 47.2535 33.0317 47.2024 33.2785 47.1032C33.5253 47.0039 33.7494 46.8585 33.9381 46.6751L46.1256 34.8626C46.507 34.4929 46.7213 33.9916 46.7213 33.4688C46.7213 32.9459 46.507 32.4446 46.1256 32.0749C45.7442 31.7052 45.2269 31.4975 44.6875 31.4975C44.1481 31.4975 43.6308 31.7052 43.2494 32.0749L34.5312 40.5287V17.7188Z"
        fill={color}
      />
    </svg>
  );
}

ArrowDown.propTypes = {
  classname: PropTypes.string,
  color: PropTypes.string,
};
ArrowDown.defaultProps = {
  classname: '',
  color: 'white',
};
export default ArrowDown;
