import React, { useEffect, useState } from 'react';
import './ScrollButton.scss';
import classNames from '../../utils/classNames';
import ArrowTop from '../../images/arrow-top';

function ScrollButton() {
  const [isVisible, setIsVisible] = useState(false);
  const toggleVisibility = () => {
    if (window.pageYOffset > 300) {
      setIsVisible(true);
    } else {
      setIsVisible(false);
    }
  };
  const scrollButton = () => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
  };
  useEffect(() => {
    window.addEventListener('scroll', toggleVisibility);
    return () => {
      window.removeEventListener('scroll', toggleVisibility);
    };
  }, []);

  return (
    <div
      tabIndex={0}
      role="button"
      type="button"
      onKeyPress={scrollButton}
      onClick={scrollButton}
      className={classNames(isVisible ? 'opacity-100' : 'opacity-0', 'toTop')}
      aria-label="Scroll to Top"
    >
      <ArrowTop />
    </div>
  );
}

export default ScrollButton;
