import React from 'react';
import PropTypes from 'prop-types';
import './Button.scss';

function Button(props) {
  const { children, onClick, className, type, testId, disabled } = props;

  return (
    <button
      data-testid={testId}
      onClick={onClick}
      className={className}
      disabled={disabled}
      type={type === 'submit' ? 'submit' : 'button'}
    >
      {children || 'Click Me'} {/* Render default children if none provided */}
    </button>
  );
}

Button.propTypes = {
  testId: PropTypes.string,
  type: PropTypes.oneOf(['button', 'submit']),
  children: PropTypes.node,
  onClick: PropTypes.func,
  className: PropTypes.string,
  disabled: PropTypes.bool,
};

Button.defaultProps = {
  testId: 'button',
  type: 'button',
  onClick: () => {},
  className: '',
  disabled: false,
  children: '',
};

export default Button;
