import React, { useEffect, useState } from 'react';
import AOS from 'aos';
import ArrowDown from '../../images/arrow-down';
import Plane from '../../images/plane';
import Cash from '../../images/cash';
import HighQuality from '../../images/hq';
import Guarantee from '../../images/guarantee';
import 'aos/dist/aos.css';
import './Main.scss';
import Slider from '../../components/Slider/Slider';

function Main() {
  const [offsetY, setOffsetY] = useState(0);
  const handleScroll = () => setOffsetY(window.pageYOffset);
  useEffect(() => {
    if (window.innerWidth > 1040) {
      window.addEventListener('scroll', handleScroll);
      return () => window.removeEventListener('scroll', handleScroll);
    }
    return () => null;
  }, []);
  useEffect(() => {
    AOS.init({ duration: 1000 }, []);
  });
  const renderContent = () => (
    <div className="container">
      <section className="parallax__content--cta">
        <h1 className="parallax__content--teg">Чому варто обрати нас</h1>
        <p>Ми пропонуємо найкращі ціни, сервіс і гарантуємо якість.</p>
        <p>
          Ми не просто продаємо вантажопідйомне обладнання - ми вирішуємо
          завдання клієнтів.
        </p>
        <p>
          Кожен клієнт для нас важливий та індивідуальний. Ми постараємося
          врахувати всі ваші побажання.
        </p>
        <p>Але якщо ви все ще вагаєтеся, ми можемо запропонувати вам.</p>
        <ArrowDown classname="arrow--down" />
      </section>
    </div>
  );

  return (
    <div className="Main">
      <div className="slider">
        <Slider />
      </div>
      <div className="parallax">
        <div className="parallax__content">{renderContent()}</div>
        <div className="parallax__background--inactive" />
        <div
          className="parallax__background"
          style={{ transform: `translateY(-${offsetY * 0.068}px)` }}
        />
        <div
          className="parallax__background-rope"
          style={{ transform: `translateY(${offsetY * -0.068}px)` }}
        />
      </div>
      <div className="blocks">
        <div className="blocks--background">
          <div className="bloc container">
            <section className="bloc--green" data-aos="flip-right">
              <h3 className="bloc--teg">Знижки постійним клієнтам</h3>
              <p className="bloc--text">Постійні клієнти отримують знижки</p>
              <Cash />
            </section>
            <section className="bloc--yellow" data-aos="flip-left">
              <h3 className="bloc--teg">
                Дотримання ГОСТів та міжнароних стандартів
              </h3>
              <p className="bloc--text">
                Ми дотримуємося всіх стандартів якості
              </p>
              <HighQuality />
            </section>
            <section className="bloc--red" data-aos="flip-right">
              <h3 className="bloc--teg">Гарантія на всі види товарів</h3>
              <p className="bloc--text">
                Гарантія на всі види товарів від 3 до 6 місяців
              </p>
              <Guarantee />
            </section>
            <section className="bloc--blue" data-aos="flip-left">
              <h3 className="bloc--teg">Швидка відправка товару</h3>
              <p className="bloc--text">Ми відправимо ваш товар неначе</p>
              <div className="bloc--blue__svgText">
                <p className="bloc--text">ЛІТА</p>
                <Plane />
                <p className="bloc--text">КОМ</p>
              </div>
            </section>
          </div>
        </div>
      </div>
    </div>
  );
}
export default Main;
