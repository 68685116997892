import React from 'react';
import PropTypes from 'prop-types';

function Logo({ classname, color }) {
  return (
    <svg
      version="1.0"
      xmlns="http://www.w3.org/2000/svg"
      width="80px"
      height="80px"
      viewBox="0 0 256.000000 256.000000"
      preserveAspectRatio="xMidYMid meet"
      className={classname}
    >
      <svg
        x="25"
        y="20"
        version="1.0"
        xmlns="http://www.w3.org/2000/svg"
        width="210px"
        height="210px"
        viewBox="0 0 3000.000000 2675.000000"
        preserveAspectRatio="xMidYMid meet"
        className={classname}
      >
        <g
          transform="translate(0.000000,2675.000000) scale(0.100000,-0.100000)"
          fill={color}
          stroke="none"
        >
          <path
            d="M18648 23050 c-126 -32 -263 -122 -686 -455 -85 -66 -169 -131 -187
-145 -52 -39 -102 -79 -216 -170 -59 -47 -153 -121 -210 -165 -56 -44 -118
-94 -138 -111 -20 -17 -65 -52 -101 -79 -36 -27 -92 -72 -126 -100 -33 -27
-91 -75 -129 -105 -107 -86 -244 -209 -262 -236 -18 -28 -10 -74 13 -74 42 0
117 55 608 448 50 40 125 98 166 129 41 32 91 71 111 88 19 16 69 56 110 87
40 32 133 103 204 159 72 55 172 133 224 172 51 40 108 83 126 97 151 117 346
260 371 273 95 49 225 80 238 58 4 -6 -2 -26 -13 -44 -20 -32 -219 -232 -461
-462 -69 -66 -204 -196 -300 -290 -97 -93 -266 -255 -375 -359 -110 -104 -243
-230 -295 -280 -53 -50 -156 -144 -228 -210 l-133 -118 -102 57 c-516 291
-728 381 -1307 556 -207 62 -397 132 -530 194 -184 85 -504 225 -642 279 -62
24 -178 70 -258 101 -80 32 -196 74 -257 95 -100 33 -119 36 -165 29 -180 -28
-360 -212 -398 -407 -39 -197 39 -323 320 -518 247 -172 640 -391 1297 -725
l363 -184 0 -61 c0 -76 -26 -115 -131 -201 -40 -32 -93 -75 -118 -95 -25 -20
-86 -69 -136 -108 -49 -39 -121 -95 -160 -126 -38 -30 -92 -73 -120 -94 -27
-21 -93 -73 -145 -114 -52 -42 -144 -114 -205 -161 -60 -46 -150 -116 -200
-155 -95 -75 -299 -232 -345 -265 -15 -11 -46 -35 -70 -52 -26 -20 -55 -33
-73 -33 -16 0 -82 18 -146 40 -64 21 -168 55 -231 74 -116 34 -166 48 -455
130 -226 64 -525 161 -770 250 -419 153 -1057 348 -1410 432 -81 19 -127 25
-175 21 -159 -11 -264 -126 -304 -334 -39 -207 10 -355 156 -473 137 -110 860
-467 1573 -777 161 -70 419 -195 443 -216 8 -6 20 -25 27 -41 45 -107 -131
-355 -372 -524 -66 -46 -171 -92 -210 -92 -90 0 -278 71 -1428 540 -216 88
-852 351 -1150 475 -406 169 -746 310 -965 400 -390 160 -928 383 -1110 460
-483 203 -1737 713 -2160 878 -609 238 -940 347 -1185 386 -145 24 -475 22
-783 -4 -32 -3 -145 -12 -252 -20 -167 -13 -366 -31 -565 -50 -27 -3 -66 -7
-85 -10 -19 -2 -57 -7 -85 -10 -27 -3 -124 -14 -215 -25 -91 -11 -185 -22
-210 -25 -25 -3 -72 -10 -105 -15 -33 -6 -116 -19 -185 -30 -166 -26 -163 -26
-335 -65 -308 -71 -548 -168 -582 -235 -48 -96 30 -273 206 -469 146 -163 571
-533 821 -718 184 -135 282 -195 619 -380 508 -279 859 -431 2581 -1113 396
-158 2529 -1012 2995 -1200 248 -100 610 -247 805 -325 1067 -429 1897 -768
2470 -1008 467 -195 515 -218 596 -284 66 -54 398 -410 1454 -1563 1106 -1207
1421 -1555 1439 -1589 l12 -21 -313 2 c-279 1 -645 15 -843 32 -322 29 -414
46 -1041 196 -154 37 -391 90 -564 126 -296 62 -492 26 -557 -100 -49 -94 -98
-327 -91 -431 13 -205 230 -381 788 -641 499 -232 995 -405 1525 -534 350 -85
830 -162 1145 -185 215 -15 355 -36 505 -75 65 -17 88 -19 133 -10 89 16 109
34 157 136 23 49 57 110 74 135 36 48 61 102 61 128 0 10 -11 45 -25 79 -47
116 -28 121 79 21 78 -73 289 -299 793 -850 153 -169 407 -445 564 -615 877
-952 1828 -1993 2067 -2264 56 -63 100 -119 99 -124 -4 -11 -41 -22 -102 -32
-53 -8 -541 -8 -685 1 -223 12 -372 28 -600 61 -115 17 -106 15 -430 78 -751
148 -1184 175 -1361 85 -127 -64 -222 -218 -253 -411 -22 -134 -1 -204 89
-294 114 -114 322 -219 680 -344 281 -98 720 -221 1018 -286 40 -9 101 -22
137 -30 308 -68 752 -142 1045 -173 355 -38 941 -62 1021 -42 90 23 204 151
204 231 0 14 15 54 33 90 55 112 84 101 292 -112 72 -73 160 -165 196 -203
137 -147 1840 -2007 2169 -2370 414 -457 636 -697 761 -825 93 -95 95 -96 219
-147 172 -70 316 -110 426 -117 50 -4 122 -16 160 -27 77 -23 266 -36 357 -25
132 17 124 56 -68 346 -142 213 -438 626 -870 1210 -594 805 -784 1061 -1236
1670 -938 1264 -1551 2092 -2000 2705 -128 176 -437 590 -684 920 -906 1207
-1581 2148 -1687 2350 -49 94 -62 152 -93 410 -23 202 -43 342 -81 580 -25
158 -1 248 76 288 52 27 175 18 314 -22 115 -34 385 -132 756 -276 729 -281
1598 -586 2165 -761 1087 -333 1882 -532 2745 -684 206 -37 319 -64 370 -90
46 -23 269 -258 1219 -1280 228 -245 467 -501 531 -570 91 -98 798 -864 1155
-1252 l62 -67 114 -31 c63 -17 193 -53 289 -80 314 -87 410 -96 418 -41 6 39
-86 202 -848 1501 -540 920 -943 1623 -1020 1781 l-32 64 45 120 c25 66 64
168 87 227 l42 107 -30 38 c-28 35 -164 169 -334 331 -37 35 -65 68 -62 75 3
7 30 24 62 39 248 117 453 212 482 225 19 9 82 38 140 66 58 27 121 55 140 62
19 7 59 25 88 39 29 15 96 46 150 70 53 24 133 61 177 81 44 21 136 63 205 95
69 32 159 73 200 92 41 20 161 75 265 123 105 48 228 105 275 127 47 22 144
67 215 100 72 33 164 76 205 95 80 38 219 103 310 146 30 14 134 62 230 107
96 45 193 90 215 100 71 32 187 87 329 154 76 36 141 66 144 66 20 0 240 119
268 145 42 40 43 67 8 138 -107 210 -670 511 -1044 557 -103 12 -215 13 -295
1 -233 -35 -403 -62 -421 -65 -12 -2 -45 -7 -75 -11 -30 -3 -79 -10 -109 -15
-30 -5 -75 -11 -100 -15 -241 -32 -337 -46 -350 -49 -8 -3 -40 -7 -70 -11 -30
-3 -68 -8 -84 -11 -16 -3 -90 -14 -165 -24 -274 -39 -345 -50 -464 -70 -154
-26 -302 -37 -311 -22 -11 18 10 56 89 156 42 54 75 99 73 100 -168 118 -285
183 -446 247 -117 47 -173 62 -217 56 -41 -5 -138 -57 -235 -128 -30 -22 -93
-67 -140 -100 -47 -33 -104 -74 -127 -92 -23 -18 -88 -66 -144 -107 -56 -41
-145 -107 -198 -146 -53 -39 -163 -116 -246 -172 l-150 -101 -170 -27 c-93
-14 -199 -30 -235 -34 -36 -5 -94 -13 -130 -19 -36 -6 -83 -13 -105 -16 -22
-4 -51 -8 -65 -10 -14 -3 -61 -9 -105 -15 -117 -15 -144 -19 -205 -30 -30 -5
-80 -12 -110 -15 -30 -3 -77 -10 -105 -15 -27 -5 -68 -11 -90 -13 -22 -3 -161
-21 -310 -42 -191 -26 -287 -35 -328 -30 -72 8 -197 58 -542 217 -148 69 -355
161 -460 206 -364 154 -702 295 -950 397 -137 57 -311 129 -385 160 -74 32
-247 104 -385 160 -137 57 -335 139 -440 183 -104 44 -388 162 -630 262 -242
100 -543 226 -670 280 -126 54 -338 143 -470 198 -323 134 -448 190 -456 204
-35 56 -152 778 -208 1278 -6 52 -20 171 -31 264 l-19 168 236 467 c129 256
295 583 368 726 73 143 212 418 310 610 98 193 215 422 260 510 45 88 126 246
180 350 196 382 279 542 335 650 31 61 104 200 160 310 56 110 131 254 165
320 35 66 96 185 137 265 221 429 265 513 311 600 246 466 342 670 333 705
-12 48 -166 137 -532 308 -88 41 -192 86 -230 100 -79 29 -182 40 -236 27z
m498 -300 c257 -128 334 -177 334 -211 0 -46 -148 -353 -502 -1039 -158 -306
-259 -502 -358 -695 -56 -110 -106 -204 -110 -208 -4 -5 -71 23 -148 62 -172
87 -195 90 -245 29 -19 -24 -60 -81 -92 -128 -65 -94 -83 -120 -189 -265 -40
-55 -102 -140 -137 -190 -87 -120 -174 -240 -289 -396 -52 -71 -124 -169 -160
-219 -36 -49 -147 -199 -248 -332 -212 -281 -222 -296 -222 -324 0 -48 40 -77
290 -214 63 -35 155 -90 204 -123 l88 -59 -4 -47 c-4 -44 -113 -275 -248 -526
-21 -38 -53 -99 -71 -135 -72 -142 -272 -506 -285 -519 -22 -24 -97 0 -307
100 -583 277 -608 288 -641 276 -23 -9 -21 -172 4 -407 11 -102 31 -297 45
-435 24 -238 28 -276 41 -371 l6 -40 228 -114 c169 -84 230 -119 234 -135 15
-61 25 -390 12 -402 -9 -9 -36 2 -1118 445 -135 56 -354 146 -485 201 -386
160 -764 318 -979 406 -277 115 -877 371 -923 395 -21 10 -41 26 -44 34 -5 13
97 119 263 272 36 33 151 141 255 239 105 99 251 236 325 304 196 181 371 344
389 362 9 9 88 83 176 164 146 136 501 470 1035 975 113 107 350 330 525 495
176 165 414 390 530 500 115 110 280 265 365 345 85 80 204 192 265 250 60 58
180 170 265 250 129 121 320 301 340 320 3 3 48 45 100 94 52 49 131 124 175
166 44 42 145 137 225 210 80 73 152 141 160 150 8 9 49 48 90 85 91 83 304
282 414 386 128 120 150 138 167 139 9 0 124 -54 255 -120z m-5239 -419 c149
-52 524 -201 586 -233 22 -11 37 -22 34 -24 -3 -3 -57 7 -121 22 -80 19 -120
24 -127 17 -14 -14 117 -135 284 -260 233 -176 502 -345 897 -563 113 -62 229
-128 258 -147 51 -32 52 -34 52 -80 0 -88 -47 -205 -107 -266 -109 -113 -220
-89 -672 141 -426 217 -1256 697 -1475 854 -12 8 8 37 73 108 51 55 120 181
140 255 15 57 14 131 -4 165 -9 17 -19 30 -23 30 -5 0 -17 7 -28 15 -17 13
-14 14 38 15 43 0 90 -12 195 -49z m-397 -204 c11 -24 20 -59 21 -78 5 -62
-32 -122 -62 -103 -6 3 -21 -2 -35 -11 -42 -27 -54 -14 -54 57 1 103 38 178
87 178 19 0 28 -10 43 -43z m1223 -187 c308 -79 954 -299 1382 -470 245 -98
515 -223 515 -238 0 -10 -4 -10 -129 13 -138 25 -265 15 -441 -34 -19 -6 -79
-15 -132 -20 -83 -9 -104 -9 -145 5 -225 76 -1283 717 -1283 778 0 14 103 -1
233 -34z m1846 -780 c168 -59 198 -73 206 -99 17 -55 -36 -109 -1049 -1071
-159 -151 -325 -308 -370 -349 -45 -41 -126 -118 -181 -171 -256 -245 -682
-633 -765 -697 -82 -62 -95 -63 -171 -7 -35 26 -143 99 -239 162 -238 156
-243 160 -227 181 11 16 54 55 254 227 32 28 105 89 163 135 57 46 123 100
146 119 51 42 241 194 287 230 18 14 77 61 132 105 54 44 128 103 164 130 36
28 102 79 146 115 75 61 107 86 190 150 18 14 70 54 115 90 100 79 121 95 241
187 52 40 128 100 169 132 41 33 91 71 110 85 19 14 76 57 125 95 93 73 290
225 354 273 20 15 41 27 47 28 6 0 75 -22 153 -50z m-12884 -479 c66 -11 174
-34 240 -51 296 -77 451 -136 1860 -713 154 -63 413 -168 575 -235 162 -66
318 -130 345 -142 28 -12 158 -66 290 -120 132 -53 422 -173 645 -265 223 -92
493 -203 600 -247 107 -44 308 -126 445 -183 138 -57 421 -174 630 -260 485
-200 943 -389 1210 -500 116 -48 323 -134 460 -190 138 -56 320 -132 405 -168
85 -36 243 -101 350 -145 107 -44 290 -119 405 -167 116 -48 379 -157 585
-242 206 -85 508 -210 670 -278 162 -68 466 -194 675 -280 209 -87 524 -217
700 -290 314 -130 399 -165 1075 -447 184 -77 490 -204 680 -283 541 -224 937
-389 1165 -485 116 -49 345 -145 510 -213 271 -113 504 -211 1295 -542 140
-59 401 -169 580 -245 316 -134 403 -170 960 -402 677 -281 1134 -480 1145
-498 9 -15 -21 -26 -172 -64 -140 -34 -203 -58 -203 -75 0 -5 39 -31 88 -57
148 -79 594 -330 767 -430 50 -28 135 -76 190 -107 55 -30 193 -106 306 -170
593 -331 1294 -689 1804 -919 179 -81 175 -80 248 -57 34 11 85 30 112 43 28
13 59 26 70 29 16 5 66 -33 235 -179 118 -102 226 -195 238 -208 l24 -23 -60
-124 c-33 -68 -69 -132 -80 -142 -18 -17 -23 -17 -55 -4 -19 9 -91 56 -160
105 -165 118 -305 202 -512 306 -330 165 -537 232 -875 282 -158 24 -480 24
-607 0 -322 -59 -459 -164 -456 -351 0 -38 6 -83 13 -100 13 -29 14 -29 22 40
12 97 34 150 87 204 102 104 258 151 526 158 319 9 593 -40 950 -170 369 -135
847 -378 812 -413 -11 -12 -280 44 -727 152 -674 163 -926 214 -1075 221
l-120 5 -62 -38 c-34 -20 -95 -71 -135 -112 -52 -53 -92 -84 -138 -106 -61
-29 -71 -31 -180 -30 -85 0 -149 7 -245 28 -362 76 -1024 249 -1540 403 -121
36 -236 70 -255 75 -19 5 -75 22 -125 38 -49 15 -151 47 -225 70 -379 118
-816 271 -1085 380 -38 16 -259 104 -490 195 -434 173 -1055 429 -1128 466
l-41 21 35 15 c53 24 130 43 171 43 54 0 58 18 13 57 -38 32 -503 268 -920
466 -110 52 -361 174 -559 271 -197 97 -360 176 -362 176 -1 0 -274 133 -606
296 -332 163 -643 316 -693 339 -49 24 -337 165 -640 315 -1715 849 -1758 870
-1917 924 -283 95 -564 136 -946 138 -308 1 -478 -37 -586 -132 -53 -47 -82
-54 -161 -35 -81 19 -320 107 -677 251 -663 267 -995 401 -2008 807 -1238 496
-1958 786 -2440 982 -489 199 -845 343 -1385 560 -1220 490 -1493 605 -1790
749 -275 134 -701 382 -897 523 -91 66 -228 190 -228 207 0 6 18 30 41 51 64
62 216 241 234 275 30 58 51 45 -622 370 -95 46 -171 85 -169 88 6 5 154 -38
356 -104 216 -71 293 -87 420 -88 163 -1 192 24 191 158 -1 87 -14 162 -52
283 -30 100 -34 129 -20 151 12 20 272 53 646 82 205 15 264 20 325 25 30 3
109 8 175 11 66 4 174 10 240 13 214 13 458 5 590 -18z m14625 -87 c36 -19 83
-50 105 -68 34 -29 38 -37 29 -53 -6 -10 -46 -86 -89 -168 -43 -83 -101 -195
-130 -250 -29 -55 -102 -197 -163 -315 -138 -271 -252 -492 -307 -595 -23 -44
-81 -154 -128 -245 -75 -144 -156 -294 -194 -356 -11 -18 -22 -14 -215 85
-287 148 -348 192 -330 241 7 17 162 247 202 300 15 19 77 105 137 190 60 85
159 223 218 305 60 83 166 229 235 325 289 403 444 600 495 631 35 22 46 19
135 -27z m-7785 -578 c242 -44 738 -186 856 -247 27 -13 39 -26 39 -41 0 -74
418 -365 911 -636 80 -44 218 -117 305 -163 87 -46 164 -90 171 -98 31 -40 -3
-149 -78 -249 -89 -118 -166 -162 -250 -143 -169 39 -948 369 -1580 671 -532
254 -728 383 -760 503 -15 58 -4 140 28 200 53 96 155 191 225 207 47 11 45
11 133 -4z m1252 -451 c534 -120 1630 -443 1914 -565 148 -63 372 -190 359
-203 -3 -3 -97 31 -210 75 -113 45 -248 96 -302 115 l-96 34 -79 -16 c-43 -9
-148 -32 -233 -52 -206 -48 -284 -49 -393 -5 -139 57 -1097 605 -1097 628 0 9
73 3 137 -11z m2019 -739 c148 -54 333 -130 395 -162 64 -32 68 -49 22 -93
-21 -20 -132 -126 -247 -236 -115 -110 -221 -211 -235 -225 -14 -13 -96 -90
-181 -169 -85 -79 -191 -178 -235 -220 -374 -354 -548 -517 -559 -523 -19 -11
-105 14 -288 83 -170 64 -526 216 -590 252 -43 23 -56 50 -36 74 17 21 154
134 248 205 41 31 98 75 125 97 28 22 91 71 140 110 50 39 126 100 170 136 44
35 122 97 174 137 102 80 128 100 252 198 90 71 85 68 249 189 205 153 271
192 335 203 46 8 148 -14 261 -56z m2198 -1375 c84 -41 152 -95 175 -139 36
-71 54 -181 131 -807 21 -170 37 -311 36 -312 -3 -4 -342 150 -353 160 -31 32
-113 648 -129 980 -8 153 -5 162 49 152 16 -3 57 -18 91 -34z m561 -276 c83
-42 155 -82 162 -88 20 -20 63 -300 113 -742 11 -93 22 -183 24 -200 3 -16 12
-104 21 -195 9 -91 20 -206 25 -257 6 -51 10 -126 10 -168 0 -74 -1 -76 -22
-70 -44 13 -373 158 -384 169 -24 25 -117 635 -164 1076 -22 211 -37 517 -26
545 12 31 82 11 241 -70z m-4695 -1800 c254 -29 403 -66 453 -113 16 -15 72
-81 125 -147 272 -338 669 -811 1941 -2315 454 -536 1314 -1553 1911 -2260
2468 -2923 3186 -3779 3201 -3819 7 -17 6 -27 -3 -32 -16 -10 -97 -17 -269
-23 l-136 -5 -265 287 c-611 663 -1547 1682 -2523 2747 -1300 1418 -1320 1440
-1366 1513 -35 55 -36 72 -6 100 20 19 162 82 185 82 5 0 31 9 58 21 146 61
182 85 171 113 -11 29 -43 33 -660 95 -176 18 -190 20 -205 42 -86 123 -434
509 -1641 1819 -1320 1432 -1495 1629 -1578 1777 -46 81 -43 112 12 126 48 12
468 6 595 -8z m682 -164 c183 -81 712 -333 1252 -595 120 -58 219 -106 221
-106 1 0 175 -85 386 -189 974 -478 1450 -711 1769 -866 503 -244 1465 -726
1539 -771 36 -22 66 -44 68 -50 2 -5 -23 -25 -54 -44 -71 -42 -140 -92 -218
-159 -32 -28 -62 -51 -65 -51 -6 0 -118 48 -410 175 -177 77 -253 105 -286
105 -30 0 -57 -28 -48 -49 23 -52 70 -364 119 -781 8 -69 17 -138 19 -155 13
-92 37 -347 33 -351 -9 -9 -191 47 -232 71 -101 59 -126 125 -170 445 -23 172
-45 417 -48 540 l-2 115 28 3 c15 2 63 -6 107 -17 89 -24 110 -26 110 -11 0
23 -172 104 -247 116 -59 9 -83 -17 -77 -83 2 -26 8 -113 14 -193 15 -190 87
-910 91 -914 2 -1 63 -26 135 -55 259 -103 346 -184 793 -741 448 -557 1545
-1976 1620 -2094 17 -28 31 -56 31 -63 0 -19 -42 -16 -155 12 -55 14 -126 28
-157 32 -74 8 -76 -6 -13 -77 80 -90 352 -433 690 -870 551 -713 981 -1265
1138 -1463 121 -153 158 -192 195 -211 48 -24 186 -69 387 -126 61 -17 160
-48 220 -68 l110 -37 365 -486 c318 -425 935 -1252 1175 -1579 44 -60 161
-218 260 -352 694 -939 865 -1183 865 -1229 0 -25 -57 -31 -175 -19 -105 10
-281 37 -326 48 -18 5 -93 85 -235 252 -298 348 -817 959 -894 1050 -133 159
-886 1047 -940 1110 -31 36 -169 198 -306 360 -138 162 -551 651 -920 1085
-1383 1631 -1693 1998 -2609 3080 -516 611 -1083 1281 -1260 1490 -177 209
-519 614 -761 900 -548 649 -852 1008 -929 1099 -54 64 -466 553 -959 1136
-756 894 -1249 1484 -1332 1595 -48 64 -64 95 -49 95 2 0 64 -27 137 -59z
m13308 -1109 c122 -56 265 -135 298 -165 12 -11 22 -28 22 -36 0 -24 -57 -104
-143 -202 l-76 -86 -83 -12 c-269 -40 -364 -54 -558 -81 -301 -43 -473 -61
-483 -51 -20 20 22 57 233 214 125 92 135 99 206 149 22 15 64 46 94 68 196
143 354 250 370 250 8 0 62 -22 120 -48z m2777 -263 c107 -25 311 -95 436
-150 126 -56 370 -182 437 -227 76 -51 77 -70 3 -116 -136 -87 -422 -206 -491
-206 -37 0 -56 8 -197 84 -61 32 -128 28 -270 -18 -66 -21 -199 -62 -295 -93
-96 -30 -206 -65 -245 -78 -38 -13 -191 -62 -340 -110 -148 -48 -342 -111
-430 -140 -88 -29 -403 -132 -700 -229 -297 -98 -558 -184 -580 -193 -22 -8
-47 -16 -55 -18 -8 -2 -116 -36 -240 -77 -124 -41 -333 -110 -465 -153 -132
-43 -285 -93 -340 -111 -211 -69 -466 -147 -585 -178 -116 -31 -124 -32 -187
-35 -59 -2 -67 1 -210 74 -330 171 -926 506 -1278 718 -306 185 -450 280 -460
305 -8 21 10 39 48 45 17 4 84 16 147 27 63 12 174 29 245 40 72 10 270 39
440 64 171 25 328 48 350 51 22 3 67 10 100 15 33 5 95 15 138 21 43 6 88 12
100 15 12 2 146 22 297 44 461 68 610 90 740 110 69 10 187 28 263 39 320 48
419 63 537 81 69 10 188 28 265 39 77 12 201 30 275 41 74 11 185 27 245 35
61 9 137 20 170 25 33 5 78 12 100 15 22 4 51 8 65 11 42 7 991 142 1055 150
33 4 67 8 75 9 8 2 44 7 80 11 36 4 67 8 70 9 3 1 37 5 75 9 107 12 388 56
393 61 13 12 139 3 219 -16z m227 -712 c32 -21 33 -47 4 -75 -17 -15 -428
-222 -443 -222 -1 0 -69 -32 -151 -71 -139 -67 -239 -115 -762 -371 -111 -54
-244 -114 -295 -132 -51 -18 -127 -50 -170 -70 -43 -20 -80 -36 -82 -36 -2 0
-41 -17 -87 -39 -45 -21 -135 -62 -198 -91 -137 -62 -184 -84 -310 -143 -52
-24 -144 -67 -205 -95 -60 -27 -159 -73 -218 -101 -59 -28 -110 -51 -111 -51
-5 0 -476 -224 -579 -275 l-78 -39 -71 12 c-84 15 -175 45 -353 117 -215 87
-505 234 -505 255 0 19 103 58 405 155 296 96 811 265 1625 535 664 220 1191
393 1385 455 99 32 205 66 235 76 53 17 174 55 325 100 39 12 81 25 95 30 272
88 481 117 544 76z m-11874 -633 c105 -37 287 -121 341 -156 63 -42 79 -72 79
-150 0 -80 31 -384 75 -733 18 -148 37 -305 40 -347 l7 -78 -38 14 c-177 64
-477 193 -502 217 -26 23 -25 20 -51 219 -111 840 -122 993 -75 1027 25 18 43
17 124 -13z m-5435 -578 c267 -55 667 -156 718 -182 22 -12 27 -21 27 -53 0
-34 6 -45 41 -75 44 -37 239 -137 429 -218 201 -87 427 -170 890 -327 576
-197 696 -256 854 -421 122 -128 161 -235 118 -320 -23 -47 -64 -68 -158 -81
-396 -55 -1505 214 -2446 595 -269 109 -872 389 -876 407 -1 8 30 54 69 102
39 49 87 123 107 165 35 74 37 82 36 182 -1 172 -30 213 -155 211 l-72 -1 19
21 c10 12 28 25 39 30 35 16 184 1 360 -35z m-503 -159 c-63 -108 -66 -203 -9
-319 45 -91 54 -130 34 -146 -45 -38 -88 48 -107 216 -14 115 2 190 53 255 43
54 62 50 29 -6z m2148 -182 c36 -2 135 -7 220 -10 667 -24 1433 -61 1577 -76
51 -5 100 -9 110 -9 35 0 -5 -20 -122 -63 -175 -64 -280 -121 -525 -284 -159
-106 -202 -125 -290 -131 -90 -5 -161 12 -494 123 -310 102 -464 158 -745 270
-312 123 -371 153 -371 187 0 10 446 5 640 -7z m4083 -503 c121 -45 219 -97
264 -142 28 -27 257 -333 857 -1143 248 -335 864 -1171 989 -1343 101 -139
129 -184 114 -184 -14 0 -133 40 -227 77 l-115 44 -100 132 c-113 148 -878
1143 -1336 1737 -305 396 -478 626 -536 710 -35 52 -73 124 -73 140 0 20 60
10 163 -28z m6557 -127 c128 -24 511 -105 635 -135 22 -5 83 -19 135 -30 452
-101 846 -216 990 -289 51 -26 60 -37 130 -152 84 -137 360 -603 510 -859 53
-91 166 -282 250 -425 592 -1005 1030 -1770 1030 -1800 0 -15 -25 -1 -67 38
-69 63 -270 371 -1351 2072 -400 629 -720 1118 -770 1175 -27 31 -31 32 -32
14 0 -32 144 -265 1035 -1669 778 -1226 864 -1364 926 -1486 24 -49 38 -99 26
-99 -2 0 -43 13 -91 30 -48 16 -117 49 -155 74 -129 86 -561 532 -1441 1486
-200 217 -428 463 -505 545 -486 518 -1315 1418 -1356 1472 -35 47 -37 65 -6
59 12 -3 60 -12 107 -21z m-4863 -2606 c115 -40 208 -93 260 -147 56 -57 272
-328 470 -587 88 -115 349 -457 579 -760 654 -856 691 -907 696 -955 3 -23 0
-25 -32 -22 -19 1 -72 12 -118 25 l-83 22 -83 105 c-45 58 -255 323 -466 590
-803 1015 -1370 1741 -1370 1754 0 15 66 3 147 -25z m492 -129 c131 -37 131
-37 312 -287 531 -731 754 -1038 1004 -1378 319 -435 530 -728 545 -756 9 -16
7 -21 -16 -29 -38 -15 -78 14 -176 124 -117 132 -481 590 -979 1231 -778 1002
-819 1057 -819 1099 0 22 38 21 129 -4z m-3349 -1385 c136 -9 399 -43 616 -80
321 -56 670 -129 687 -145 11 -9 -104 -26 -308 -45 -194 -18 -315 -36 -315
-47 0 -4 2 -8 5 -8 3 0 58 -15 123 -34 138 -40 287 -80 667 -176 915 -232
1114 -293 1286 -394 77 -45 119 -89 145 -151 34 -82 17 -207 -32 -240 -27 -17
-97 -38 -174 -50 -54 -9 -318 -9 -410 0 -507 48 -1071 166 -1725 360 -99 30
-229 70 -290 90 -120 39 -658 227 -664 232 -2 1 15 27 38 57 23 30 56 88 74
128 26 62 31 89 35 174 13 308 -163 374 -284 107 -16 -36 -28 -84 -31 -125 -5
-65 -4 -71 41 -160 48 -95 52 -128 16 -128 -28 0 -81 49 -101 95 -37 82 -18
249 43 369 28 54 106 136 148 154 61 27 184 32 410 17z m3360 -259 c0 -24
-146 -156 -260 -237 -165 -116 -213 -143 -319 -179 -85 -30 -101 -32 -169 -26
-271 23 -1459 291 -1486 335 -7 11 35 19 139 26 44 3 103 7 130 10 28 2 95 7
150 10 55 3 132 7 170 10 39 3 113 7 165 10 52 3 174 9 270 15 227 12 891 40
1045 43 138 2 165 -1 165 -17z m181 -59 c105 -90 516 -558 1449 -1652 211
-247 434 -508 495 -580 397 -464 1249 -1476 1516 -1800 188 -228 269 -332 269
-346 0 -26 -116 10 -181 57 -25 19 -111 102 -191 186 -344 364 -1856 2012
-3099 3378 -357 393 -455 506 -484 562 l-28 54 24 36 c28 41 141 168 150 168
3 0 39 -28 80 -63z"
          />
          <path
            d="M2829 20649 c-25 -4 -73 -21 -107 -38 -53 -26 -62 -36 -71 -68 -15
-52 -14 -96 3 -119 12 -16 29 -19 118 -22 106 -4 261 16 312 40 44 20 47 99 6
151 -39 49 -150 74 -261 56z"
          />
          <path
            d="M2801 20269 c-65 -16 -91 -36 -91 -71 0 -54 29 -99 79 -121 41 -18
58 -19 171 -13 153 9 196 14 238 32 35 14 38 21 21 58 -16 34 -71 71 -144 96
-70 24 -211 34 -274 19z"
          />
          <path
            d="M3071 19950 c-152 -41 -181 -73 -119 -132 63 -59 313 -218 342 -218
18 0 118 107 155 165 54 85 40 134 -49 181 -54 28 -232 30 -329 4z"
          />
          <path
            d="M3558 19763 c-27 -30 -87 -142 -109 -205 -12 -34 -11 -41 7 -65 27
-37 187 -145 248 -168 47 -18 49 -18 62 0 32 43 10 293 -32 377 -45 90 -123
117 -176 61z"
          />
          <path
            d="M7598 17821 c-34 -40 -68 -119 -68 -158 0 -13 10 -33 21 -44 18 -18
30 -20 69 -17 79 8 150 82 150 157 0 33 -6 44 -37 70 -54 44 -92 42 -135 -8z"
          />
          <path
            d="M8070 17710 c-57 -8 -117 -47 -160 -101 -76 -96 -38 -154 82 -128 53
12 93 49 124 114 42 89 27 127 -46 115z"
          />
          <path
            d="M8275 17581 c-63 -39 -95 -92 -95 -157 0 -42 29 -74 68 -74 70 0 182
124 166 185 -8 33 -49 65 -83 65 -14 0 -39 -8 -56 -19z"
          />
          <path
            d="M8522 17465 c-47 -32 -132 -125 -132 -145 0 -24 16 -43 52 -61 32
-16 46 -18 68 -10 77 27 160 115 160 171 0 39 -35 80 -68 80 -15 0 -51 -16
-80 -35z"
          />
          <path
            d="M8799 17350 c-53 -57 -109 -137 -109 -156 0 -32 33 -47 91 -41 73 6
131 44 159 102 20 41 20 46 6 73 -18 35 -62 72 -84 72 -9 0 -37 -23 -63 -50z"
          />
          <path
            d="M9165 17246 c-42 -18 -132 -102 -162 -150 l-26 -40 23 -18 c24 -20
82 -38 120 -38 46 0 104 53 140 129 27 55 20 96 -20 116 -35 18 -37 18 -75 1z"
          />
          <path
            d="M9433 17105 c-67 -29 -124 -158 -94 -214 28 -51 148 -29 197 36 40
52 47 141 13 172 -26 24 -69 27 -116 6z"
          />
          <path
            d="M9734 16968 c-66 -69 -87 -106 -87 -151 0 -49 37 -87 84 -87 53 0
151 108 164 182 6 33 3 41 -23 68 -46 45 -87 42 -138 -12z"
          />
          <path
            d="M10075 16843 c-32 -35 -74 -106 -85 -144 -7 -24 -5 -34 11 -50 48
-48 152 -10 199 74 19 32 20 42 10 72 -13 41 -53 75 -86 75 -13 0 -35 -12 -49
-27z"
          />
          <path
            d="M10407 16688 c-37 -36 -82 -109 -92 -150 -5 -21 -2 -29 16 -39 54
-28 122 -6 182 60 48 53 52 108 11 140 -39 31 -77 27 -117 -11z"
          />
          <path
            d="M10782 16588 c-41 -11 -111 -81 -151 -152 -36 -62 -36 -60 -15 -80
22 -22 99 -20 149 4 22 11 58 38 79 59 34 34 40 48 44 93 6 75 -26 98 -106 76z"
          />
          <path
            d="M11140 16433 c-49 -19 -150 -139 -150 -178 0 -31 53 -75 90 -75 44 0
107 88 135 188 13 46 -29 82 -75 65z"
          />
          <path
            d="M11380 16319 c-83 -33 -151 -161 -115 -216 15 -22 22 -25 56 -20 110
15 194 170 123 226 -30 24 -30 23 -64 10z"
          />
          <path
            d="M11655 16184 c-65 -33 -105 -89 -105 -144 0 -41 47 -90 87 -90 47 0
122 59 155 123 33 65 -1 126 -72 127 -19 0 -48 -7 -65 -16z"
          />
          <path
            d="M12054 16059 c-27 -3 -69 -18 -93 -33 -91 -58 -119 -215 -38 -216 82
-1 256 176 227 230 -12 23 -34 27 -96 19z"
          />
          <path
            d="M12375 15906 c-30 -13 -81 -63 -123 -120 -65 -86 23 -154 114 -88 54
40 144 140 144 161 0 43 -81 71 -135 47z"
          />
          <path
            d="M12634 15792 c-69 -50 -144 -140 -144 -173 0 -34 38 -58 99 -61 90
-3 216 122 197 197 -17 68 -86 85 -152 37z"
          />
          <path
            d="M12995 15656 c-64 -28 -158 -168 -142 -211 8 -20 39 -19 90 5 62 29
93 62 116 125 26 68 26 72 2 85 -24 13 -29 12 -66 -4z"
          />
          <path
            d="M13231 15547 c-59 -31 -103 -93 -109 -156 -4 -44 -1 -53 22 -76 30
-30 60 -29 106 4 36 25 108 135 116 177 13 71 -52 96 -135 51z"
          />
          <path
            d="M13532 15376 c-79 -87 -114 -183 -75 -204 42 -24 149 44 179 115 8
18 14 53 14 78 0 40 -3 47 -26 55 -38 15 -38 14 -92 -44z"
          />
          <path
            d="M13799 15231 c-80 -81 -104 -138 -74 -171 44 -49 146 -6 205 87 35
55 38 100 10 131 -37 39 -67 29 -141 -47z"
          />
          <path
            d="M14108 15123 c-74 -70 -111 -166 -76 -201 59 -59 240 90 223 182 -15
80 -73 87 -147 19z"
          />
          <path
            d="M14408 15055 c-39 -22 -93 -97 -108 -149 -11 -40 -10 -49 4 -71 9
-14 22 -25 30 -25 22 0 83 53 117 103 27 39 33 59 34 100 0 48 -2 52 -25 54
-14 2 -37 -4 -52 -12z"
          />
          <path
            d="M14658 14960 c-9 -6 -39 -38 -66 -73 -77 -97 -93 -167 -38 -167 58 0
118 48 156 126 24 49 26 87 4 108 -18 18 -32 20 -56 6z"
          />
          <path
            d="M14860 14874 c-34 -22 -99 -104 -122 -155 -22 -50 -22 -53 -2 -73 14
-14 19 -14 52 0 49 20 81 51 103 99 19 40 24 99 13 129 -8 20 -12 20 -44 0z"
          />
          <path
            d="M15137 14700 c-18 -22 -45 -61 -60 -88 -22 -41 -24 -51 -14 -72 15
-30 49 -37 79 -16 35 24 128 138 128 156 0 17 -58 60 -82 60 -9 0 -32 -18 -51
-40z"
          />
          <path
            d="M15446 14606 c-80 -29 -149 -132 -127 -189 15 -38 51 -44 106 -17 74
36 129 118 123 184 -3 27 -7 31 -33 33 -16 2 -47 -4 -69 -11z"
          />
          <path
            d="M15704 14490 c-81 -41 -174 -132 -174 -169 0 -17 51 -38 110 -46 50
-6 57 -4 76 17 33 37 93 143 100 177 6 27 3 32 -20 41 -34 13 -23 15 -92 -20z"
          />
          <path
            d="M15980 14393 c-51 -19 -101 -102 -104 -172 -1 -38 3 -47 23 -57 20
-10 31 -10 60 2 69 28 141 133 124 182 -16 45 -56 62 -103 45z"
          />
          <path
            d="M16250 14256 c-52 -34 -94 -117 -87 -169 7 -52 28 -69 68 -55 34 12
179 164 179 188 0 9 -10 26 -23 38 -31 29 -91 28 -137 -2z"
          />
          <path
            d="M16559 14120 c-43 -23 -117 -103 -125 -137 -13 -52 24 -73 95 -53 85
23 157 121 129 174 -22 41 -44 45 -99 16z"
          />
          <path
            d="M16824 14050 c-52 -21 -114 -122 -114 -187 0 -35 35 -65 68 -57 90
21 166 180 112 234 -22 22 -33 23 -66 10z"
          />
          <path
            d="M17095 13930 c-53 -22 -123 -139 -111 -186 14 -53 72 -52 142 1 102
78 77 230 -31 185z"
          />
          <path
            d="M17345 13828 c-63 -22 -125 -100 -125 -158 0 -93 64 -118 130 -52 41
41 70 117 70 184 0 33 -3 38 -22 37 -13 0 -36 -5 -53 -11z"
          />
          <path
            d="M17615 13637 c-30 -31 -60 -74 -70 -102 -17 -43 -17 -50 -4 -74 30
-53 85 -35 179 59 76 76 84 104 41 142 -50 45 -84 39 -146 -25z"
          />
          <path
            d="M17888 13550 c-43 -23 -103 -94 -112 -134 -13 -53 42 -84 97 -55 56
30 147 133 147 166 0 14 -57 43 -82 43 -7 -1 -30 -10 -50 -20z"
          />
          <path
            d="M18171 13443 c-67 -33 -117 -135 -92 -189 8 -19 18 -24 45 -24 28 0
42 8 76 48 66 74 102 130 98 155 -3 19 -10 22 -48 25 -27 1 -59 -5 -79 -15z"
          />
          <path
            d="M18405 13350 c-55 -35 -113 -138 -99 -175 16 -40 103 -16 153 41 35
39 58 111 43 134 -14 22 -63 23 -97 0z"
          />
          <path
            d="M18589 13240 c-52 -54 -73 -135 -49 -181 24 -45 108 -4 146 71 62
121 -10 202 -97 110z"
          />
          <path
            d="M18812 13153 c-58 -63 -84 -115 -80 -161 l3 -37 54 -3 c50 -3 55 -1
91 36 65 69 71 170 12 201 -30 16 -32 15 -80 -36z"
          />
          <path
            d="M19006 13076 c-83 -77 -105 -216 -34 -216 92 0 189 124 157 202 -24
57 -72 63 -123 14z"
          />
          <path
            d="M19280 12963 c-122 -123 -72 -320 52 -205 84 78 94 252 15 252 -11 0
-41 -21 -67 -47z"
          />
          <path
            d="M19517 12840 c-45 -40 -87 -97 -87 -118 0 -19 52 -72 71 -72 37 0
159 131 159 171 0 18 -57 59 -82 59 -8 0 -36 -18 -61 -40z"
          />
          <path
            d="M19799 12757 c-49 -33 -102 -114 -94 -147 8 -34 50 -70 83 -70 39 0
86 32 105 73 24 50 22 122 -3 147 -26 26 -49 25 -91 -3z"
          />
          <path
            d="M10390 19979 c-121 -54 -172 -162 -115 -240 54 -74 58 -90 40 -139
-10 -25 -20 -62 -22 -82 -6 -45 7 -49 66 -18 102 51 184 255 159 393 -20 108
-44 124 -128 86z"
          />
        </g>
      </svg>

      <g
        transform="translate(0.000000,256.000000) scale(0.100000,-0.100000)"
        fill={color}
        stroke="none"
      >
        <path
          d="M1174 2518 c-22 -10 -72 -88 -57 -88 18 0 54 32 69 62 18 35 16 39
-12 26z"
        />
        <path
          d="M1222 2514 c-30 -20 -58 -74 -39 -74 18 0 40 19 66 58 25 36 12 44
-27 16z"
        />
        <path
          d="M1264 2496 c-32 -32 -43 -56 -26 -56 14 0 51 30 72 60 19 26 19 30 5
30 -9 0 -32 -15 -51 -34z"
        />
        <path
          d="M1354 2520 c-19 -7 -64 -59 -64 -73 0 -13 41 8 65 33 31 32 30 51 -1
40z"
        />
        <path
          d="M1112 2513 c-11 -4 -52 -73 -52 -88 0 -15 43 20 60 48 28 45 26 54
-8 40z"
        />
        <path
          d="M1372 2483 c-30 -32 -33 -39 -17 -41 11 -3 34 12 59 37 38 38 39 41
17 41 -14 0 -37 -15 -59 -37z"
        />
        <path
          d="M1041 2489 c-20 -21 -45 -79 -35 -79 17 0 46 25 59 50 27 52 13 68
-24 29z"
        />
        <path
          d="M1430 2475 c-34 -35 -34 -35 -9 -35 16 0 38 13 59 35 34 35 34 35 9
35 -16 0 -38 -13 -59 -35z"
        />
        <path
          d="M987 2482 c-18 -20 -40 -73 -33 -80 13 -12 66 58 66 88 0 15 -15 12
-33 -8z"
        />
        <path
          d="M1492 2465 c-33 -31 -35 -34 -15 -35 22 0 83 44 83 61 0 19 -32 7
-68 -26z"
        />
        <path
          d="M933 2473 c-21 -21 -40 -79 -30 -90 16 -15 61 64 55 96 -3 13 -7 12
-25 -6z"
        />
        <path
          d="M1540 2450 c-34 -28 -34 -29 -11 -30 21 0 101 45 101 56 0 2 -12 4
-27 4 -16 0 -43 -13 -63 -30z"
        />
        <path
          d="M884 2462 c-6 -4 -16 -16 -22 -27 -10 -17 -11 -18 -12 -2 -1 48 -44
-5 -56 -68 -7 -38 -6 -39 11 -25 10 8 24 28 32 45 l14 30 -4 -27 c-8 -54 35
-17 51 45 10 37 7 44 -14 29z"
        />
        <path d="M1596 2434 c-52 -46 4 -45 59 0 l30 25 -30 0 c-18 0 -42 -10 -59 -25z" />
        <path
          d="M1652 2416 c-27 -19 -30 -25 -16 -30 15 -6 104 37 104 49 0 15 -61 1
-88 -19z"
        />
        <path
          d="M760 2405 c-6 -8 -14 -32 -17 -55 -7 -51 12 -54 33 -5 24 59 15 97
-16 60z"
        />
        <path
          d="M1711 2399 c-25 -15 -31 -23 -22 -32 8 -8 22 -5 56 11 25 12 45 26
45 32 0 17 -43 11 -79 -11z"
        />
        <path
          d="M710 2385 c-7 -8 -15 -35 -18 -60 -4 -39 -2 -44 11 -39 17 6 21 17
32 77 6 39 -4 48 -25 22z"
        />
        <path
          d="M1773 2379 c-24 -12 -40 -26 -37 -31 8 -13 61 -2 92 18 20 13 22 18
11 25 -19 12 -18 12 -66 -12z"
        />
        <path
          d="M660 2355 c-17 -20 -26 -105 -12 -105 17 0 26 20 32 72 7 50 1 59
-20 33z"
        />
        <path d="M1805 2342 l-30 -17 32 -3 c28 -3 93 20 93 33 0 12 -68 3 -95 -13z" />
        <path
          d="M613 2329 c-12 -15 -16 -38 -14 -84 1 -27 2 -28 17 -14 11 11 14 30
12 64 -2 38 -5 45 -15 34z"
        />
        <path
          d="M1853 2318 c-31 -15 -29 -28 4 -28 32 0 93 21 93 32 0 12 -71 9 -97
-4z"
        />
        <path
          d="M556 2284 c-3 -9 -6 -36 -6 -61 0 -39 2 -44 15 -33 10 8 15 30 15 61
0 48 -12 64 -24 33z"
        />
        <path
          d="M1908 2288 c-16 -5 -28 -14 -28 -19 0 -12 62 -11 97 2 22 8 24 12 13
19 -19 12 -47 11 -82 -2z"
        />
        <path
          d="M510 2251 c-12 -23 -12 -67 -1 -96 8 -19 8 -19 19 2 14 26 16 113 2
113 -5 0 -14 -9 -20 -19z"
        />
        <path
          d="M1945 2246 c-18 -13 -18 -14 3 -20 26 -9 105 11 97 24 -9 15 -79 12
-100 -4z"
        />
        <path
          d="M473 2219 c-15 -15 -17 -77 -3 -99 15 -24 21 -6 18 54 -2 46 -5 55
-15 45z"
        />
        <path
          d="M2002 2217 c-41 -15 -32 -27 22 -28 39 -1 40 -1 13 -11 -39 -14 -24
-28 31 -28 60 0 84 13 54 29 -11 6 -29 11 -39 11 -15 0 -16 2 -3 10 32 20 -32
34 -78 17z"
        />
        <path
          d="M426 2183 c-8 -8 -8 -86 0 -107 12 -30 24 -5 24 49 0 51 -9 73 -24
58z"
        />
        <path
          d="M386 2135 c-9 -23 -7 -87 3 -103 15 -25 33 13 25 55 -9 53 -19 70
-28 48z"
        />
        <path
          d="M2067 2134 c-15 -15 7 -24 59 -24 40 0 55 4 52 13 -5 13 -99 23 -111
11z"
        />
        <path d="M347 2094 c-11 -12 -7 -66 7 -99 l13 -30 8 27 c8 29 -15 114 -28 102z" />
        <path
          d="M2105 2091 c-11 -18 35 -33 78 -26 38 7 39 8 22 21 -21 16 -91 19
-100 5z"
        />
        <path
          d="M304 2026 c-3 -13 2 -41 11 -62 15 -34 18 -36 24 -19 5 11 4 35 0 55
-5 19 -9 38 -9 43 0 17 -20 5 -26 -17z"
        />
        <path
          d="M2140 2036 c16 -20 114 -23 108 -3 -3 7 -27 13 -63 15 -52 3 -57 1
-45 -12z"
        />
        <path
          d="M2170 2000 c0 -16 37 -30 82 -30 l43 1 -24 19 c-24 21 -101 28 -101
10z"
        />
        <path
          d="M274 1987 c-9 -25 4 -83 23 -98 15 -13 16 -9 11 39 -6 58 -23 87 -34
59z"
        />
        <path
          d="M2219 1940 c15 -13 38 -20 65 -20 l41 1 -24 19 c-15 13 -38 20 -65
20 l-41 -1 24 -19z"
        />
        <path d="M240 1920 c0 -41 28 -96 42 -82 7 7 5 26 -7 61 -19 57 -35 67 -35 21z" />
        <path
          d="M2242 1894 c17 -18 108 -37 108 -23 0 17 -47 39 -84 39 -37 0 -39 -1
-24 -16z"
        />
        <path d="M210 1864 c0 -27 29 -84 42 -84 13 0 9 37 -9 75 -20 41 -33 45 -33 9z" />
        <path
          d="M2275 1842 c11 -11 40 -23 65 -27 l45 -7 -21 22 c-13 13 -39 24 -65
27 l-44 5 20 -20z"
        />
        <path
          d="M180 1819 c0 -25 37 -89 51 -89 15 0 10 28 -11 70 -22 44 -40 52 -40
19z"
        />
        <path
          d="M2290 1802 c0 -15 22 -29 64 -41 48 -13 57 -7 30 19 -21 21 -94 38
-94 22z"
        />
        <path
          d="M160 1763 c0 -26 38 -93 53 -93 15 0 6 42 -18 80 -30 47 -35 49 -35
13z"
        />
        <path
          d="M2310 1751 c0 -14 23 -30 68 -45 36 -13 42 -13 42 -1 0 16 -38 38
-82 49 -16 3 -28 2 -28 -3z"
        />
        <path
          d="M140 1705 c0 -14 13 -39 30 -57 24 -27 29 -30 30 -14 0 21 -40 96
-52 96 -4 0 -8 -11 -8 -25z"
        />
        <path
          d="M2333 1697 c2 -7 19 -22 38 -35 34 -23 34 -23 5 -16 -54 13 -5 -44
52 -61 28 -8 29 8 2 35 -15 15 -16 20 -5 20 20 0 8 28 -18 41 -37 20 -79 29
-74 16z"
        />
        <path d="M120 1659 c0 -29 55 -106 64 -90 8 13 -8 51 -38 86 l-25 30 -1 -26z" />
        <path
          d="M100 1595 c0 -8 16 -32 35 -53 31 -33 34 -35 35 -15 0 22 -44 83 -61
83 -5 0 -9 -7 -9 -15z"
        />
        <path
          d="M2360 1591 c0 -21 85 -80 97 -67 12 12 -8 36 -46 56 -45 23 -51 24
-51 11z"
        />
        <path
          d="M90 1535 c0 -18 66 -92 74 -84 16 16 -38 99 -64 99 -5 0 -10 -7 -10
-15z"
        />
        <path
          d="M2380 1519 c11 -19 66 -59 83 -59 20 0 -1 36 -32 57 -44 30 -66 31
-51 2z"
        />
        <path
          d="M80 1479 c0 -20 68 -94 78 -84 12 11 -11 53 -43 78 -34 27 -35 27
-35 6z"
        />
        <path
          d="M2380 1482 c0 -17 33 -53 62 -68 35 -18 39 -17 27 13 -8 21 -89 72
-89 55z"
        />
        <path
          d="M80 1419 c0 -23 15 -42 63 -78 35 -27 18 18 -23 62 -38 41 -40 41
-40 16z"
        />
        <path
          d="M2390 1419 c0 -14 64 -79 79 -79 18 0 12 25 -11 47 -33 30 -68 47
-68 32z"
        />
        <path
          d="M70 1359 c0 -14 67 -79 81 -79 20 0 7 30 -26 59 -37 32 -55 39 -55
20z"
        />
        <path
          d="M2390 1359 c0 -14 67 -79 81 -79 21 0 7 31 -28 59 -40 33 -53 38 -53
20z"
        />
        <path
          d="M2395 1298 c6 -22 61 -78 77 -78 22 0 -30 72 -65 92 -17 8 -18 7 -12
-14z"
        />
        <path
          d="M77 1293 c10 -28 24 -43 55 -59 26 -14 28 -13 28 5 0 12 -15 31 -37
46 -44 30 -55 31 -46 8z"
        />
        <path
          d="M2390 1245 c0 -9 18 -35 40 -58 30 -32 40 -37 40 -24 0 27 -17 53
-50 77 -25 18 -30 19 -30 5z"
        />
        <path
          d="M80 1237 c0 -16 24 -39 58 -57 49 -25 38 15 -13 48 -40 26 -45 27
-45 9z"
        />
        <path
          d="M2390 1180 c0 -11 16 -37 35 -58 26 -28 35 -33 35 -19 0 27 -27 75
-50 87 -17 9 -20 8 -20 -10z"
        />
        <path
          d="M80 1182 c0 -23 83 -82 97 -69 10 11 -14 40 -48 57 -42 22 -49 24
-49 12z"
        />
        <path
          d="M2380 1122 c0 -21 55 -90 65 -80 10 9 -2 42 -26 72 -24 30 -39 33
-39 8z"
        />
        <path
          d="M90 1122 c0 -18 27 -42 63 -57 36 -15 38 -15 35 2 -2 9 -21 28 -43
40 -43 24 -55 28 -55 15z"
        />
        <path
          d="M2370 1069 c0 -15 13 -43 30 -64 l30 -38 0 30 c0 17 -11 42 -30 63
l-29 35 -1 -26z"
        />
        <path
          d="M102 1058 c6 -17 49 -46 81 -54 41 -9 25 22 -24 46 -44 23 -62 25
-57 8z"
        />
        <path
          d="M2350 1021 c0 -5 5 -22 10 -37 9 -24 9 -25 -5 -14 -24 20 -19 -36 8
-79 l22 -36 3 27 c2 15 -2 37 -9 50 -12 23 -12 23 10 4 21 -19 21 -19 21 5 0
27 -35 89 -50 89 -5 0 -10 -4 -10 -9z"
        />
        <path
          d="M120 1002 c0 -17 37 -42 69 -48 26 -6 33 -4 29 7 -8 25 -98 62 -98
41z"
        />
        <path
          d="M130 950 c0 -14 38 -41 70 -50 41 -12 49 -6 26 19 -17 19 -96 45 -96
31z"
        />
        <path
          d="M2316 915 c-7 -19 30 -105 45 -105 17 0 9 45 -15 84 -19 32 -24 35
-30 21z"
        />
        <path
          d="M160 880 c9 -17 64 -40 97 -40 28 0 -2 30 -45 44 -59 21 -65 20 -52
-4z"
        />
        <path
          d="M2290 853 c0 -10 10 -38 22 -63 l22 -45 4 32 c3 33 -21 93 -38 93 -6
0 -10 -8 -10 -17z"
        />
        <path d="M180 831 c0 -14 60 -41 90 -41 25 0 26 16 2 29 -31 17 -92 24 -92 12z" />
        <path
          d="M2269 808 c-3 -27 4 -54 19 -83 14 -29 15 -29 24 -9 7 16 4 32 -11
63 -22 43 -30 50 -32 29z"
        />
        <path
          d="M209 772 c14 -26 111 -46 111 -22 0 14 -29 27 -78 35 -41 7 -43 6
-33 -13z"
        />
        <path
          d="M2240 743 c0 -32 21 -93 32 -93 12 0 10 66 -4 95 -15 33 -28 32 -28
-2z"
        />
        <path
          d="M233 726 c6 -17 49 -36 85 -36 33 0 42 13 20 27 -22 13 -109 21 -105
9z"
        />
        <path
          d="M2210 686 c0 -44 17 -89 30 -81 13 8 13 44 -1 84 -14 41 -29 40 -29
-3z"
        />
        <path
          d="M270 665 c14 -17 73 -30 96 -21 13 5 14 9 4 21 -8 10 -30 15 -62 15
-43 0 -49 -2 -38 -15z"
        />
        <path
          d="M2176 656 c-3 -14 -4 -38 -1 -53 l6 -28 -14 30 c-13 26 -16 27 -22
12 -9 -21 3 -107 14 -107 5 0 11 15 15 33 5 24 9 29 16 17 14 -22 24 12 17 62
-7 51 -22 68 -31 34z"
        />
        <path
          d="M302 618 c3 -8 27 -14 63 -16 52 -3 57 -1 45 12 -16 20 -114 23 -108
4z"
        />
        <path
          d="M335 581 c-14 -24 82 -42 114 -22 11 7 10 11 -7 20 -25 13 -99 15
-107 2z"
        />
        <path
          d="M2106 574 c-8 -21 -6 -75 4 -99 7 -19 9 -18 19 8 8 21 8 40 1 68 -12
43 -15 46 -24 23z"
        />
        <path
          d="M788 540 c-20 -12 -34 -68 -20 -82 6 -6 19 7 36 38 14 25 26 48 26
50 0 7 -25 3 -42 -6z"
        />
        <path
          d="M1668 543 c-20 -5 -42 -38 -52 -75 -12 -51 25 -25 59 40 18 37 18 40
-7 35z"
        />
        <path
          d="M1696 519 c-25 -30 -34 -59 -18 -59 9 0 82 75 82 84 0 18 -42 2 -64
-25z"
        />
        <path
          d="M380 525 c19 -23 129 -18 114 6 -3 5 -32 9 -66 9 -53 0 -59 -2 -48
-15z"
        />
        <path
          d="M730 520 c-11 -11 -20 -25 -20 -30 0 -16 29 -70 38 -70 4 0 7 27 7
60 0 33 -1 60 -2 60 -2 0 -12 -9 -23 -20z"
        />
        <path
          d="M826 509 c-51 -61 -25 -67 37 -9 41 39 42 40 15 40 -17 0 -35 -10
-52 -31z"
        />
        <path
          d="M2067 533 c-4 -3 -7 -28 -7 -55 0 -69 24 -59 28 10 3 48 -4 62 -21
45z"
        />
        <path
          d="M881 498 c-26 -25 -30 -34 -18 -36 17 -3 97 45 97 59 0 21 -49 6 -79
-23z"
        />
        <path
          d="M1585 507 c-8 -13 -18 -38 -21 -56 -6 -30 -5 -33 11 -24 25 14 59
103 39 103 -7 0 -20 -10 -29 -23z"
        />
        <path
          d="M1745 504 c-42 -45 -31 -58 20 -24 17 11 41 20 54 20 20 0 21 2 11
15 -19 23 -58 18 -85 -11z"
        />
        <path
          d="M965 500 c-11 -5 -31 -18 -45 -29 l-25 -21 27 0 c31 0 101 38 92 51
-6 11 -22 11 -49 -1z"
        />
        <path
          d="M1530 495 c-16 -20 -26 -95 -12 -95 14 0 27 23 40 73 8 29 8 37 -3
37 -7 0 -18 -7 -25 -15z"
        />
        <path
          d="M2021 486 c-6 -15 -8 -45 -5 -67 5 -32 9 -37 20 -28 16 13 19 86 4
109 -7 11 -11 9 -19 -14z"
        />
        <path d="M422 493 c-24 -10 0 -23 43 -23 50 0 77 8 69 21 -5 9 -90 11 -112 2z" />
        <path
          d="M1486 477 c-19 -14 -40 -107 -25 -107 14 0 36 43 42 83 7 42 7 42
-17 24z"
        />
        <path
          d="M1782 477 c-29 -17 -18 -27 29 -27 21 0 41 -4 44 -10 3 -5 10 -10 16
-10 15 0 10 19 -11 40 -23 23 -45 25 -78 7z"
        />
        <path
          d="M685 467 c-12 -30 3 -64 35 -77 43 -18 49 -5 13 33 -17 17 -33 39
-36 47 -4 12 -6 12 -12 -3z"
        />
        <path
          d="M993 463 c-40 -20 -42 -33 -8 -33 30 0 85 26 85 40 0 15 -41 11 -77
-7z"
        />
        <path
          d="M507 460 c-47 -8 -54 -16 -30 -29 13 -7 33 -6 61 1 69 17 44 40 -31
28z"
        />
        <path
          d="M1983 459 c-15 -16 -17 -109 -3 -109 16 0 22 23 18 74 -2 38 -5 45
-15 35z"
        />
        <path
          d="M1429 444 c-20 -23 -33 -107 -16 -101 19 6 38 55 35 88 -3 30 -4 31
-19 13z"
        />
        <path
          d="M1036 434 c-22 -13 -26 -18 -15 -25 8 -5 23 -6 34 -3 42 11 75 27 75
35 0 15 -63 9 -94 -7z"
        />
        <path
          d="M1794 413 c2 -16 4 -34 5 -40 1 -18 31 -16 31 1 0 8 -7 17 -16 20 -9
4 -14 9 -10 13 3 3 15 -2 26 -12 15 -14 23 -16 32 -7 16 16 -17 52 -48 52 -19
0 -22 -5 -20 -27z"
        />
        <path
          d="M532 419 c-38 -11 -34 -29 7 -29 43 0 96 21 79 31 -16 11 -43 10 -86
-2z"
        />
        <path
          d="M1378 413 c-21 -24 -32 -89 -17 -99 15 -9 26 11 34 64 8 51 4 59 -17
35z"
        />
        <path
          d="M1930 411 c-5 -11 -10 -39 -10 -63 l1 -43 19 24 c12 14 20 38 20 62
0 42 -13 51 -30 20z"
        />
        <path
          d="M1087 404 c-28 -15 -29 -34 -2 -34 23 0 95 31 95 41 0 14 -62 10 -93
-7z"
        />
        <path
          d="M600 388 c-19 -5 -39 -13 -44 -18 -15 -15 43 -23 74 -10 53 22 30 43
-30 28z"
        />
        <path
          d="M670 387 c0 -24 35 -47 73 -47 43 0 49 17 10 26 -16 4 -40 13 -55 21
-24 11 -28 11 -28 0z"
        />
        <path
          d="M1328 383 c-19 -21 -34 -103 -20 -103 17 0 30 24 37 73 8 52 6 56
-17 30z"
        />
        <path
          d="M1887 378 c-12 -17 -17 -40 -15 -65 l3 -38 18 27 c12 17 17 40 15 65
l-3 38 -18 -27z"
        />
        <path
          d="M1141 371 c-31 -17 -32 -18 -12 -24 19 -5 83 7 105 20 12 8 -15 23
-39 22 -11 0 -35 -8 -54 -18z"
        />
        <path
          d="M1839 350 c-21 -32 -33 -103 -16 -97 19 6 38 55 35 90 l-3 32 -16
-25z"
        />
        <path
          d="M625 346 c-25 -11 -27 -15 -13 -21 27 -11 63 -2 66 18 4 20 -12 21
-53 3z"
        />
        <path
          d="M1205 345 c-22 -8 -42 -15 -44 -15 -3 0 0 -5 7 -12 9 -9 20 -7 47 8
19 10 35 22 35 26 0 10 4 10 -45 -7z"
        />
        <path
          d="M1271 346 c-6 -7 -14 -35 -17 -62 l-6 -49 20 20 c11 11 22 39 25 63
5 43 -2 52 -22 28z"
        />
        <path d="M1776 343 c-6 -14 -5 -15 5 -6 7 7 10 15 7 18 -3 3 -9 -2 -12 -12z" />
        <path d="M1788 320 c-23 -36 -34 -94 -17 -88 20 7 41 54 37 86 l-3 27 -17 -25z" />
        <path
          d="M703 317 c2 -6 10 -15 19 -21 20 -12 95 4 88 19 -7 13 -112 15 -107
2z"
        />
        <path
          d="M1216 308 c-17 -29 -29 -88 -16 -88 17 0 40 32 40 55 0 13 3 30 6 39
9 24 -14 19 -30 -6z"
        />
        <path
          d="M650 300 c0 -5 11 -10 25 -10 14 0 25 5 25 10 0 6 -11 10 -25 10 -14
0 -25 -4 -25 -10z"
        />
        <path
          d="M1731 297 c-6 -8 -14 -28 -17 -45 -9 -44 20 -44 35 -1 19 54 9 78
-18 46z"
        />
        <path
          d="M2132 293 c-14 -27 -24 -103 -13 -103 17 0 31 28 37 74 7 48 -6 63
-24 29z"
        />
        <path d="M365 289 c-4 -6 -5 -13 -1 -16 8 -8 56 6 56 17 0 13 -47 13 -55 -1z" />
        <path
          d="M788 288 c-44 -11 -49 -25 -14 -34 27 -6 99 23 90 37 -6 11 -27 10
-76 -3z"
        />
        <path
          d="M1160 281 c-5 -11 -10 -28 -10 -39 0 -12 -9 -22 -23 -26 -12 -3 -38
-21 -57 -41 -32 -33 -33 -35 -11 -35 14 0 37 15 60 40 20 22 41 40 48 40 11 0
23 31 23 63 0 23 -17 22 -30 -2z"
        />
        <path
          d="M1325 275 c-25 -16 -33 -25 -22 -25 24 0 77 28 77 40 0 15 -11 12
-55 -15z"
        />
        <path
          d="M2170 261 c0 -37 2 -42 15 -31 19 16 19 31 0 56 -13 18 -14 16 -15
-25z"
        />
        <path
          d="M1676 268 c-20 -28 -20 -48 -1 -48 14 0 35 37 35 61 0 16 -19 9 -34
-13z"
        />
        <path
          d="M710 270 c0 -5 5 -10 10 -10 6 0 10 5 10 10 0 6 -4 10 -10 10 -5 0
-10 -4 -10 -10z"
        />
        <path d="M1110 265 c-7 -8 -9 -23 -5 -32 6 -17 7 -17 21 1 26 36 12 64 -16 31z" />
        <path
          d="M1363 258 c-40 -20 -48 -38 -17 -38 25 0 95 40 89 50 -9 15 -26 12
-72 -12z"
        />
        <path
          d="M2092 271 c-14 -8 -45 -101 -37 -109 12 -12 44 33 50 70 8 44 5 51
-13 39z"
        />
        <path d="M378 258 c-39 -14 -35 -28 7 -28 37 0 85 18 85 32 0 11 -58 9 -92 -4z" />
        <path
          d="M835 251 c-33 -15 -37 -20 -22 -26 17 -7 29 -5 72 10 18 6 18 5 -3
-13 -14 -13 -18 -23 -12 -27 17 -11 52 2 85 30 l30 26 -37 -5 c-29 -4 -36 -2
-31 10 7 20 -33 18 -82 -5z"
        />
        <path d="M1633 263 c-17 -6 -16 -33 1 -33 7 0 16 9 19 20 5 20 2 22 -20 13z" />
        <path d="M1063 253 c-17 -6 -16 -23 1 -23 8 0 16 7 20 15 6 15 1 17 -21 8z" />
        <path
          d="M1418 237 c-21 -12 -38 -25 -38 -29 0 -17 58 -7 88 15 54 40 13 51
-50 14z"
        />
        <path
          d="M440 229 c-43 -20 -44 -21 -21 -30 17 -7 33 -4 62 13 21 11 36 25 33
30 -8 12 -25 9 -74 -13z"
        />
        <path
          d="M760 240 c0 -5 5 -10 11 -10 5 0 7 5 4 10 -3 6 -8 10 -11 10 -2 0 -4
-4 -4 -10z"
        />
        <path
          d="M2032 238 c-15 -19 -43 -90 -37 -96 13 -14 65 63 65 98 0 14 -15 13
-28 -2z"
        />
        <path
          d="M962 213 c-48 -31 -53 -43 -18 -43 27 0 89 47 80 61 -8 14 -16 11
-62 -18z"
        />
        <path
          d="M1484 221 c-42 -26 -51 -41 -23 -41 23 0 89 39 89 53 0 13 -35 7 -66
-12z"
        />
        <path
          d="M503 208 c-50 -26 -55 -38 -13 -38 19 0 41 11 62 30 28 25 30 29 13
30 -11 0 -39 -10 -62 -22z"
        />
        <path
          d="M1537 203 c-42 -29 -47 -43 -16 -43 25 0 91 49 83 61 -9 15 -28 10
-67 -18z"
        />
        <path
          d="M1984 222 c-13 -8 -54 -73 -54 -84 0 -14 27 -8 42 10 18 20 40 82 30
82 -4 0 -12 -4 -18 -8z"
        />
        <path
          d="M583 203 c-17 -8 -39 -23 -49 -34 -16 -18 -16 -19 9 -19 29 0 97 53
81 63 -6 3 -24 -1 -41 -10z"
        />
        <path
          d="M1012 192 c-18 -15 -32 -30 -32 -34 0 -20 44 -5 78 27 36 33 36 34
12 34 -14 0 -40 -12 -58 -27z"
        />
        <path
          d="M1615 202 c-41 -25 -59 -48 -45 -57 18 -11 89 43 90 68 0 11 -13 8
-45 -11z"
        />
        <path
          d="M1898 175 c-37 -53 -48 -53 -17 -1 39 66 -13 39 -70 -36 -12 -16 -9
-17 36 -14 26 2 51 4 56 5 10 1 47 63 47 79 0 24 -21 11 -52 -33z"
        />
        <path
          d="M645 194 c-59 -30 -83 -64 -46 -64 22 0 67 34 81 61 13 23 5 24 -35
3z"
        />
        <path
          d="M1132 173 c-32 -32 -35 -38 -18 -41 12 -3 33 11 60 37 38 38 39 41
17 41 -13 0 -38 -15 -59 -37z"
        />
        <path
          d="M1195 170 c-38 -39 -38 -40 -14 -40 23 0 36 10 78 63 12 15 11 17 -6
17 -10 0 -37 -18 -58 -40z"
        />
        <path
          d="M1264 174 c-19 -19 -34 -40 -34 -45 0 -14 26 -11 40 6 16 19 31 20
24 1 -10 -26 35 -6 57 26 17 23 19 32 9 41 -9 9 -17 5 -37 -18 -16 -20 -21
-23 -14 -8 23 47 0 45 -45 -3z"
        />
        <path
          d="M1655 179 c-37 -33 -44 -49 -20 -49 16 0 85 57 85 71 0 18 -30 8 -65
-22z"
        />
        <path
          d="M681 174 c-27 -23 -42 -54 -25 -54 23 0 83 52 84 73 0 15 -30 6 -59
-19z"
        />
        <path
          d="M739 168 c-33 -37 -36 -48 -13 -48 15 0 74 57 74 72 0 19 -34 5 -61
-24z"
        />
        <path
          d="M802 173 c-32 -48 -34 -53 -17 -53 20 0 65 44 65 65 0 24 -27 17 -48
-12z"
        />
        <path d="M1710 165 l-34 -35 25 0 c23 0 55 25 73 58 16 27 -32 10 -64 -23z" />
        <path
          d="M1770 163 c-25 -31 -27 -39 -14 -41 16 -4 59 30 75 61 19 34 -28 18
-61 -20z"
        />
        <path
          d="M1367 160 c-18 -29 -18 -30 1 -30 25 0 46 25 38 45 -8 23 -20 18 -39
-15z"
        />
        <path
          d="M845 154 c-9 -14 -14 -28 -11 -31 9 -9 56 26 56 42 0 24 -26 17 -45
-11z"
        />
        <path
          d="M900 150 c-8 -16 -8 -20 3 -20 8 0 20 6 26 14 9 10 8 15 -4 19 -9 4
-19 -2 -25 -13z"
        />
        <path
          d="M1420 150 c-8 -16 -8 -20 3 -20 8 0 20 6 26 14 9 10 8 15 -4 19 -9 4
-19 -2 -25 -13z"
        />
        <path
          d="M1475 150 c-3 -5 1 -10 10 -10 9 0 13 5 10 10 -3 6 -8 10 -10 10 -2
0 -7 -4 -10 -10z"
        />
      </g>
    </svg>
  );
}
Logo.propTypes = {
  classname: PropTypes.string,
  color: PropTypes.string,
};
Logo.defaultProps = {
  classname: '',
  color: 'white',
};

export default Logo;
