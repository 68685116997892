import React from 'react';
import PropTypes from 'prop-types';

function ArrowTop({ color, classname }) {
  return (
    <svg
      width="60"
      height="60"
      viewBox="0 0 60 60"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={classname}
    >
      <path
        d="M29.9999 1.19995C14.0909 1.19995 1.1969 14.094 1.1969 30C1.19651 33.7825 1.94125 37.5281 3.38859 41.0229C4.83594 44.5176 6.95754 47.6929 9.63222 50.3676C12.3069 53.0423 15.4823 55.1639 18.977 56.6113C22.4717 58.0586 26.2173 58.8033 29.9999 58.803C45.9029 58.803 58.7999 45.909 58.7999 30C58.7999 14.094 45.9029 1.19995 29.9999 1.19995ZM29.9969 52.8C27.0028 52.8 24.0379 52.2102 21.2717 51.0644C18.5055 49.9186 15.992 48.2392 13.8749 46.122C11.7577 44.0048 10.0783 41.4914 8.93245 38.7251C7.78664 35.9589 7.1969 32.9941 7.1969 30C7.1969 27.0058 7.78664 24.041 8.93245 21.2748C10.0783 18.5085 11.7577 15.9951 13.8749 13.8779C15.992 11.7607 18.5055 10.0813 21.2717 8.9355C24.0379 7.78969 27.0028 7.19995 29.9969 7.19995C32.991 7.19995 35.9559 7.78969 38.7221 8.9355C41.4883 10.0813 44.0018 11.7607 46.1189 13.8779C48.2361 15.9951 49.9155 18.5085 51.0613 21.2748C52.2072 24.041 52.7969 27.0058 52.7969 30C52.7969 32.9941 52.2072 35.9589 51.0613 38.7251C49.9155 41.4914 48.2361 44.0048 46.1189 46.122C44.0018 48.2392 41.4883 49.9186 38.7221 51.0644C35.9559 52.2102 32.991 52.8 29.9969 52.8ZM29.9999 16.5L43.4999 30H35.9999V42H23.9999V30H16.4999L29.9999 16.5Z"
        fill={color}
      />
    </svg>
  );
}

ArrowTop.propTypes = {
  classname: PropTypes.string,
  color: PropTypes.string,
};
ArrowTop.defaultProps = {
  classname: '',
  color: 'white',
};

export default ArrowTop;
