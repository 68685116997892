import React from 'react';
import { Routes, Route, Navigate } from 'react-router-dom';
import Main from '../pages/Main/Main';
import Delivery from '../pages/Delivery/Delivery';
import NotFound from '../pages/NotFound/NotFound';
import Catalog from '../pages/Catalog/Catalog';
import Warranty from '../pages/Warranty/Warranty';
import About from '../pages/About/About';
import Requisites from '../pages/Requisites/Requisites';
import Gallery from '../pages/Gallery/Gallery';
import Discounts from '../pages/Discounts/Discounts';
import Map from '../pages/Map/Map';
import TermsUse from '../pages/TermsUse/TermsUse';
import Product from '../pages/Product/Product';

function AppRoutes() {
  return (
    <Routes>
      <Route path="/main" element={<Main />} />
      <Route path="/" element={<Navigate replace to="/main" />} />
      <Route exact path="/delivery" element={<Delivery />} />
      <Route exact path="/catalog/" element={<Catalog />} />
      <Route exact path="/products/:id" element={<Product />} />
      <Route exact path="/about" element={<About />} />
      <Route exact path="/warranty" element={<Warranty />} />
      <Route exact path="/requisites" element={<Requisites />} />
      <Route exact path="/gallery" element={<Gallery />} />
      <Route exact path="/discounts" element={<Discounts />} />
      <Route exact path="/map" element={<Map />} />
      <Route exact path="/terms-use" element={<TermsUse />} />
      <Route exact path="*" element={<NotFound />} />
    </Routes>
  );
}

export default AppRoutes;
