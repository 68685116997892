import React from 'react';
import './Slider.scss';
import PropTypes from 'prop-types';
import arrowLeft from '../../images/arrow-left.svg';
import arrowRight from '../../images/arrow-right.svg';

export default function BtnSlider({ direction, moveSlide }) {
  return (
    <button
      type="submit"
      onClick={moveSlide}
      className={direction === 'next' ? 'btn-slide next' : 'btn-slide prev'}
      aria-label="slide"
    >
      <img src={direction === 'next' ? arrowRight : arrowLeft} alt="" />
    </button>
  );
}

BtnSlider.propTypes = {
  direction: PropTypes.string,
  moveSlide: PropTypes.func,
};
BtnSlider.defaultProps = {
  direction: ' ',
  moveSlide: ' ',
};
