import React from 'react';
import { NavLink } from 'react-router-dom';
import './Footer.scss';
import Logo from '../../images/logo';

function Footer() {
  return (
    <footer className="footer">
      <div className="container">
        <div className=" footer--container">
          <section className="footer--links">
            <div className="footer--links__block">
              <NavLink className="footer--links__link" to="/about">
                Про нас
              </NavLink>
              <NavLink className="footer--links__link" to="/warranty">
                Гарантія
              </NavLink>
              <NavLink className="footer--links__link" to="/delivery">
                Доставка
              </NavLink>
              <NavLink className="footer--links__link" to="/requisites">
                Реквізити
              </NavLink>
            </div>
            <div className="footer--links__block">
              <NavLink className="footer--links__link" to="/gallery">
                Галерея
              </NavLink>
              <NavLink className="footer--links__link" to="/discounts">
                Знижки
              </NavLink>
              <NavLink className="footer--links__link" to="/map">
                Мапа сайту
              </NavLink>
              <NavLink className="footer--links__link" to="/terms-use">
                Умови використання
              </NavLink>
            </div>
          </section>

          <section className="footer__center">
            <h2 className="footer--title">Засоби зв&apos;язку</h2>
            <div>
              <span>Пошта:</span>
              <a
                className="footer__center--contacts"
                href="mailto:inter-kanat@ukr.net"
              >
                inter-kanat@ukr.net
              </a>
            </div>
            <div>
              <span>Телефон:</span>
              <a className="footer__center--contacts" href="tel:+380675779282">
                +38 (067) 577-92-82
              </a>
            </div>
            <div>
              <span>Телефон:</span>
              <a className="footer__center--contacts" href="tel:+380677449572">
                +38 (067) 744-95-72
              </a>
            </div>
            <div className="footer__center--address">
              <h2 className="footer--title">Адреса</h2>
              <p>м. Київ, вул. Причальна, 5А </p>
            </div>
          </section>
          <section className="footer__right">
            <div className="footer__right--schedule">
              <h2 className="footer--title">Графік роботи</h2>
              <p>ПН - ПТ: 9:00 - 17:00</p>
              <p>СБ - НД: Вихідний</p>
            </div>
            <NavLink className="footer__right--item" to="/catalog">
              Каталог
            </NavLink>
          </section>
        </div>
        <div className="footer--bottom">
          <div className="footer--bottom__left">
            <Logo color="#C1C1C1" classname="footer--bottom__left--logo" />
            <p>ТОВ “Літаком”, 2022, litacom.com.ua</p>
          </div>
          <div className="footer--bottom__right">
            <p>Всі права захищені © 2016-2022</p>
            <p>All rights reserved © 2016-2022</p>
          </div>
        </div>
      </div>
    </footer>
  );
}

export default Footer;
