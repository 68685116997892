import React, { useEffect } from 'react';
import AOS from 'aos';
import './Delivery.scss';

const delivery = require('../../images/delivery.png');
const sat = require('../../images/sat.png');
const newPost = require('../../images/new-post.png');

function Delivery() {
  useEffect(() => {
    AOS.init({ duration: 1000 }, []);
  });
  return (
    <div className="container">
      <h1 className="title">Доставка</h1>
      <div className="delivery">
        <p className="delivery--title">
          Стандартний термін відвантаження продукції - від одного до пяти
          робочих днів.
        </p>
        <section>
          <h2 className="delivery--heading">
            Ви можете отримати придбану продукцію наступними способами:{' '}
          </h2>
          <ul className="delivery--list">
            <li>Франко-склад постачальника:</li>
            <li>
              Клієнт, після отримання повідомлення про готовність продукції до
              відвантаження, мають можливість самостійно забрати замовлення з
              нашого складу за адресою: м. Київ, вул. Причальна, 5А{' '}
            </li>
            <li>Доставка по Україні</li>
            <li>
              Доставка продукції здійснюється у будь який населений пункт
              України наступними транспортними службами:
            </li>
          </ul>
        </section>
        <div className="delivery--images">
          <div data-aos="zoom-in-down" className="delivery--images__block">
            <img
              alt="Delivery"
              className="delivery--images__img"
              src={delivery}
            />
            Делівері
          </div>
          <div data-aos="zoom-in" className="delivery--images__block">
            <img alt="SAT" className="delivery--images__img" src={sat} />
            SAT
          </div>
          <div data-aos="zoom-in-up" className="delivery--images__block">
            <img
              alt="New-post"
              className="delivery--images__img"
              src={newPost}
            />
            Нова-Пошта
          </div>
          <p className="delivery--images__marked">
            *Або іншим перевізником за вибором постачальника
          </p>
        </div>
        <ul className="delivery--list ">
          <li>Оплата товару</li>
          <li>Оплата товару тільки при повній або 50% передплаті</li>
        </ul>
      </div>
    </div>
  );
}

export default Delivery;
