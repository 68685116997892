import React, { useState } from 'react';
import './Slider.scss';
import { Link } from 'react-router-dom';
import BtnSlider from './BtnSlider';
import dataSlider from './dataSlider';

export default function Slider() {
  const [slideIndex, setSlideIndex] = useState(1);

  const nextSlide = () => {
    if (slideIndex !== dataSlider.length) {
      setSlideIndex(slideIndex + 1);
    } else if (slideIndex === dataSlider.length) {
      setSlideIndex(1);
    }
  };

  const prevSlide = () => {
    if (slideIndex !== 1) {
      setSlideIndex(slideIndex - 1);
    } else if (slideIndex === 1) {
      setSlideIndex(dataSlider.length);
    }
  };

  const moveDot = (index) => {
    setSlideIndex(index);
  };
  return (
    <div className="block">
      <div className="block--slider">
        {dataSlider.map((obj, index) => {
          return (
            <Link
              to="/catalog"
              key={obj.id}
              className={
                slideIndex === index + 1 ? 'slide active-anim' : 'slide'
              }
            >
              <img
                src={`${process.env.PUBLIC_URL}/Imgs/img${index + 1}.jpg`}
                alt=" "
              />
            </Link>
          );
        })}
        <BtnSlider moveSlide={nextSlide} direction="next" />
        <BtnSlider moveSlide={prevSlide} direction="prev" />
      </div>

      <div className="block--dots">
        {dataSlider.map((obj, index) => (
          // eslint-disable-next-line jsx-a11y/no-static-element-interactions
          <div
            onClick={() => moveDot(index + 1)}
            className={slideIndex === index + 1 ? 'dot active' : 'dot'}
            onKeyDown={() => moveDot(index + 1)}
            key={obj.id}
          />
        ))}
      </div>
    </div>
  );
}
