import React from 'react';
import Products from '../../components/Products/Products';
import './Catalog.scss';

function ProductList() {
  return (
    <div className="container">
      <div className="catalog">
        <h1 className="title">Каталог</h1>
        <Products />
      </div>
    </div>
  );
}

export default ProductList;
