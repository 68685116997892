import React from 'react';
import './Modal.scss';
import PropTypes from 'prop-types';

function Modal({ active, setActive, children }) {
  return (
    // eslint-disable-next-line jsx-a11y/click-events-have-key-events,jsx-a11y/no-static-element-interactions
    <div
      className={active ? 'modal active' : 'modal'}
      onClick={() => setActive(false)}
    >
      {/* eslint-disable-next-line jsx-a11y/click-events-have-key-events,jsx-a11y/no-static-element-interactions */}
      <div
        className={active ? 'modal__content active' : 'modal__content'}
        onClick={(e) => e.stopPropagation()}
      >
        {children}
      </div>
    </div>
  );
}

Modal.propTypes = {
  active: PropTypes.bool,
  // eslint-disable-next-line react/forbid-prop-types
  setActive: PropTypes.any,
  children: PropTypes.element,
};
Modal.defaultProps = {
  active: false,
  setActive: null,
  children: null,
};

export default Modal;
