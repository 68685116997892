import React, { useEffect, useState } from 'react';
import axios from 'axios';

function Gallery() {
  const [gallery, setGallery] = useState([]);
  useEffect(() => {
    const getGallery = async () => {
      try {
        const res = await axios.get('//31.131.31.43:2145/api/gallery');
        setGallery(res.data);
        // eslint-disable-next-line no-empty
      } catch (err) {}
    };

    getGallery();
  }, []);
  return (
    <div className="gallery">
      {gallery.map((item) => (
        <div className="gallery__item" key={item._id}>
          <img src={item.img} alt="gallery" className="gallery__item--image" />
        </div>
      ))}
    </div>
  );
}

export default Gallery;
