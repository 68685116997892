import React from 'react';
import './About.scss';

function About() {
  return (
    <div className="container">
      <h1 className="title">Умови використання</h1>
      <div className="about">
        <h3 className="about--title">
          ТОВ &rdquo;ЛІТАКОМ&rdquo; - виробничо-комерційна фірма, заснована в
          2016 році, в м. Київ.
        </h3>
        <p>
          Має власне підприємство з виготовлення строп канатних, ланцюгових,
          текстильних, усіх типів та різних по вантажопід&apos;ємності.
        </p>
        <ul className="about__list">
          Канатні стропи - виготовляються двома методами:
          <li>Спосіб заплітання згідно ДСТУ </li>
          <li>
            Спосіб опресування алюмінієвої втулки(Згідно ДСТУ і EN) на 500-та
            тонному пресі.
          </li>
        </ul>
        <p>
          Кожний виріб, виготовлений нами, підтверджується паспортом якості.
          Фірма також спеціалізується на продажі сталевих канатів. На складі в
          наявності продукція сталевих канатів(тросів) в широкому асортименті в
          діаметрі від 1мм до 53.5мм за ДСТУ 2688-80, 7668-80, 3077-80, 3088-74,
          3063-80, 3066-80, 3069-80, EN та ін. Канати мають сертифікати
          виробників України та ЄС.
        </p>
        <ul className="about__list">
          Серед наших клієнтів:
          <li>Полтавський ГОК</li>
          <li>Побужський феронікелевий комбінат</li>
          <li>Порт Південний</li>
          <li>Метінвест</li>
          <li>ПБГ Ковальська та інші підприємства та фірми України</li>
        </ul>
      </div>
    </div>
  );
}

export default About;
