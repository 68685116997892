import React, { useEffect, useState } from 'react';
import axios from 'axios';
import Product from '../Product/Product';
import Pagination from '../Pagination/Pagination';

function Products() {
  const [products, setProducts] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage] = useState(12);
  const lastItemIndex = currentPage * itemsPerPage;
  const firstItemIndex = lastItemIndex - itemsPerPage;
  const currentItem = products.slice(firstItemIndex, lastItemIndex);
  const paginate = (pageNumber) => setCurrentPage(pageNumber);

  useEffect(() => {
    const getProducts = async () => {
      try {
        const res = await axios.get('//31.131.31.43:2145/api/products');
        setProducts(res.data);
        // eslint-disable-next-line no-empty
      } catch (err) {}
    };
    getProducts();
  }, []);

  return (
    <div className="catalog">
      <div className="catalog--products">
        {currentItem.map((item) => (
          <Product item={item} key={item._id} />
        ))}
      </div>
      <Pagination
        itemsPerPage={itemsPerPage}
        totalPages={products.length}
        paginate={paginate}
      />
    </div>
  );
}

export default Products;
