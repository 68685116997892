import React from 'react';
import { useNavigate } from 'react-router-dom';
import './NotFound.scss';
import Button from '../../components/Button/Button';

function NotFound() {
  const navigate = useNavigate();
  return (
    <section className="error">
      <h1 className="error__block">
        <span className="error__block--404">404</span>
      </h1>
      <p className="error--text">Oops...</p>
      <p className="error--text">This page does not exist.</p>
      <Button
        className="error--button"
        onClick={() => {
          navigate('/');
        }}
      >
        BACK TO MAIN PAGE
      </Button>
    </section>
  );
}

export default NotFound;
