import React from 'react';
import PropTypes from 'prop-types';

function Contacts({ classname, color }) {
  return (
    <svg
      width="45"
      height="43"
      viewBox="0 0 45 43"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={classname}
    >
      <path
        d="M21.497 0.00861054C20.7406 0.052224 20.1278 0.102547 19.641 0.15958C16.1567 0.568876 12.893 1.72631 9.97592 3.58827C6.79973 5.61462 4.20485 8.39582 2.46093 11.6467C1.0777 14.2266 0.307288 16.8434 0.0446487 19.8662C-0.0148829 20.5137 -0.0148829 22.4058 0.0446487 23.0533C0.307288 26.0761 1.0777 28.6929 2.46093 31.2728C4.59706 35.255 7.99036 38.5059 12.1471 40.5524C14.84 41.8776 17.5714 42.6156 20.7266 42.8673C21.4025 42.9243 23.3775 42.9243 24.0534 42.8673C27.2086 42.6156 29.94 41.8776 32.6329 40.5524C36.7896 38.5059 40.1829 35.255 42.3191 31.2728C43.7023 28.6929 44.4727 26.0761 44.7353 23.0533C44.7949 22.4058 44.7949 20.5137 44.7353 19.8662C44.4727 16.8434 43.7023 14.2266 42.3191 11.6467C40.1829 7.66446 36.7896 4.41357 32.6329 2.36709C29.996 1.06875 27.2716 0.320615 24.2635 0.0723533C23.6892 0.0220301 21.9313 -0.0182285 21.497 0.00861054ZM24.0849 2.62207C28.6723 2.99781 32.9026 4.86984 36.2188 7.98653C39.6472 11.2173 41.6747 15.384 42.0739 20.0339C42.13 20.6814 42.13 22.2381 42.0739 22.8856C41.6747 27.5321 39.6472 31.7022 36.2188 34.933C32.8535 38.0966 28.6233 39.9452 23.8783 40.3175C23.2024 40.3712 21.5776 40.3712 20.9017 40.3175C16.0516 39.9351 11.6988 37.9926 8.32654 34.7082C5.02429 31.4841 3.09477 27.4314 2.70606 22.8856C2.65003 22.2381 2.65003 20.6814 2.70606 20.0339C3.10527 15.3874 5.13285 11.2173 8.56117 7.98653C11.6358 5.09462 15.5229 3.26285 19.7286 2.72607C21.1854 2.54155 22.6421 2.50464 24.0849 2.62207Z"
        fill={color}
      />
      <path
        d="M17.6975 6.90624C17.5049 6.95656 15.3197 8.17774 15.1902 8.30858C14.8925 8.60045 14.8295 8.99969 15.0256 9.36537C15.2602 9.79815 19.0632 16.0818 19.1613 16.1926C19.3609 16.4207 19.7846 16.5448 20.0963 16.4643C20.2573 16.424 22.4145 15.2398 22.6001 15.0922C22.8487 14.8942 22.9783 14.4547 22.8732 14.1629C22.8452 14.089 21.9067 12.5022 20.7861 10.6402C19.1368 7.89928 18.7165 7.22495 18.587 7.11089C18.3488 6.89617 18.0127 6.81901 17.6975 6.90624Z"
        fill={color}
      />
      <path
        d="M13.7824 9.82496C13.026 10.2678 12.6548 10.5295 12.2626 10.8885C11.0685 11.9822 10.7498 13.1161 10.995 15.4041C11.5728 20.8256 15.7295 28.4614 20.548 32.9569C21.8087 34.1345 23.1079 35.0738 24.1654 35.5704C24.8343 35.8857 25.3281 36.0132 25.9969 36.0434C26.8969 36.0837 27.7619 35.8387 29.068 35.1678C29.4638 34.9665 29.5968 34.8826 29.5828 34.8457C29.5653 34.8021 25.5347 28.0923 25.4156 27.9078L25.3631 27.834L25.2265 27.9011C24.9849 28.0185 24.5857 28.1326 24.2635 28.1695C23.5106 28.2601 22.6316 28.0051 21.7702 27.4448C19.7146 26.1129 17.8446 23.1908 17.3473 20.5371C17.0637 19.0341 17.2773 17.823 17.9602 17.0547C18.0757 16.9239 18.4644 16.6119 18.5415 16.5884C18.566 16.5817 17.7711 15.2263 16.4719 13.0691C15.3127 11.1434 14.3532 9.55657 14.3392 9.5398C14.3252 9.52638 14.0766 9.65722 13.7824 9.82496Z"
        fill={color}
      />
      <path
        d="M28.7634 25.0495C28.5708 25.0764 28.5043 25.1099 27.2646 25.7943C26.7183 26.0962 26.2176 26.3948 26.1475 26.4586C25.8954 26.6968 25.7938 27.0725 25.8989 27.3677C25.9584 27.5355 29.9435 34.1614 30.0906 34.3392C30.3392 34.6411 30.833 34.7317 31.2322 34.5472C31.5334 34.4096 33.3824 33.3831 33.5259 33.2723C33.722 33.1214 33.8411 32.8698 33.8411 32.5947C33.8411 32.4705 33.8236 32.3229 33.7991 32.2726C33.68 32.0042 29.6844 25.4186 29.5758 25.3112C29.3622 25.0965 29.0821 25.0059 28.7634 25.0495Z"
        fill={color}
      />
    </svg>
  );
}
Contacts.propTypes = {
  classname: PropTypes.string,
  color: PropTypes.string,
};
Contacts.defaultProps = {
  classname: '',
  color: '#C1C1C1',
};

export default Contacts;
