import React from 'react';
import PropTypes from 'prop-types';

function Hq({ classname, color }) {
  return (
    <svg
      width="64"
      height="64"
      viewBox="0 0 64 64"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={classname}
    >
      <path
        d="M50.6667 10.6667H13.3333C11.9188 10.6667 10.5623 11.2286 9.5621 12.2288C8.5619 13.229 8 14.5855 8 16V48C8 49.4145 8.5619 50.7711 9.5621 51.7712C10.5623 52.7714 11.9188 53.3333 13.3333 53.3333H50.6667C53.6 53.3333 56 50.9333 56 48V16C56 13.0667 53.6 10.6667 50.6667 10.6667ZM50.6667 48H13.3333V16H50.6667V48ZM20 34.6667H25.3333V40H29.3333V24H25.3333V30.6667H20V24H16V40H20V34.6667ZM37.3333 40H39.3333V44H43.3333V40H45.3333C46.8 40 48 38.8 48 37.3333V26.6667C48 25.2 46.8 24 45.3333 24H37.3333C35.8667 24 34.6667 25.2 34.6667 26.6667V37.3333C34.6667 38.8 35.8667 40 37.3333 40ZM38.6667 28H44V36H38.6667V28Z"
        fill={color}
      />
    </svg>
  );
}

Hq.propTypes = {
  classname: PropTypes.string,
  color: PropTypes.string,
};
Hq.defaultProps = {
  classname: '',
  color: 'black',
};

export default Hq;
